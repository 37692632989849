import Footer from "./footer";
import Header from "./Header";

const Layout = ({ children }) => {
  return (
    <div className="layout d-flex justify-content-between flex-column vh-100">
      <Header />
      <main className="flex-grow-1">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;

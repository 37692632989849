
import TiktokIcon from '../../../assets/images/home/tiktok_sign_up.svg';
import InstagramIcon from "../../../assets/images/instagram_img.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext, INSTAGRAM_CLIENT_ID } from '../../../context/Provider';
import { toast } from 'react-toastify';
import { useContext, useEffect, useState } from 'react';
import henceforthApi from '../../../utils/henceforthApi';
import { getPrimaryWalletType } from '../../../utils/work_contant';
import authAction from '../../../context/actions/auth/authAction';
import { Spinner } from '../../BootstrapCompo';
import Modals from '../../Modals';

export default () => {

    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { authState, authDispatch, scrollToTop, setLoading, connectTiktok, tikTokLoginLoading } = useContext(GlobalContext)

    henceforthApi.setToken(authState.access_token)
    const [openTikTok, setOpenTikTok] = useState(false)

    const connectInstagram = async () => {
        let code = query.get("code")
        try {
            let redirect_uri = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
                ? `https://localhost:3000${location.pathname}`
                : `${window.location.origin}${location.pathname}`

            let items = {
                type: "INSTAGRAM",
                code,
                language: "ENGLISH",
                redirect_uri
            }
            setLoading(true)
            let apiRes = await henceforthApi.Auth.connectSocialAccount(items)
            let data = apiRes.data
            let walletType = getPrimaryWalletType(data.wallet)
            authAction.initialiseProfile({ ...data, walletType })(authDispatch);
            setLoading(false)
            navigate(`/signup/step-2`)
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.body) {
                toast.error(error.response.body.error_description)
                navigate(`/signup/step-2`)
            }
        }
    }

    const goNextPage = async () => {
        if (authState?.instagram?.user_name || authState?.tiktok?.user_name) {
            navigate(`/signup/step-3`)
        } else {
            toast.warn("please select any social network")
        }
    }

    const deleteInstagram = async (id) => {
        try {
            let apiRes = await henceforthApi.Profile.deleteSocial(id)
            let data = apiRes.data
            authAction.deleteInstagram({ ...data, })(authDispatch);
        } catch (error) {
            console.log("error", error);
            debugger
        }
    }
    const deleteTiktok = async (id) => {
        try {
            let apiRes = await henceforthApi.Profile.deleteSocial(id)
            let data = apiRes.data
            authAction.deleteTiktok({ ...data, })(authDispatch);
        } catch (error) {
            console.log("error", error);
            debugger
        }
    }
    useEffect(() => {
        if (query.has("code")) {
            connectInstagram()
        }
    }, [query.get("code")])

    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [authState.access_token])

    return <>
        <p className="text-gray mb-2 fw-medium">Step 2 of 3</p>
        <p className="text-gray fw-semi-bold mb-0">If you want to use Everlens social, you need to connect Instagram or Tiktok.</p>
        <div className="row my-3 py-lg-2">
            <div className="col-lg-6 my-2">
                {query.has("code") ?
                    <span className="text-decoration-none" >
                        <div className="d-flex justify-content-center align-items-center text-center square-box" >
                            <div>
                                <img src={InstagramIcon} className="img-fluid user mb-2" />
                                <p className="text-gray mb-0 fs-12">Connecting....</p>
                                <button type='button' className="btn-icon text-theme fs-12" ><Spinner /></button>
                            </div>
                        </div>
                    </span> : authState.instagram && authState.instagram.user_name ?
                        <span className="text-decoration-none" >
                            <div className="d-flex justify-content-center align-items-center text-center square-box" >
                                <div>
                                    <img src={authState.instagram.profile_pic ? `${henceforthApi.FILES.imageMedium(authState.instagram.profile_pic)}` : InstagramIcon} className="img-fluid user mb-2" />
                                    <p className="fw-medium mb-0 text-black">{authState.instagram.user_name}</p>
                                    <p className="text-gray mb-0 fs-12">@{authState.instagram.user_name}</p>
                                    <button type='button' className="btn-icon text-theme fs-12" onClick={() => deleteInstagram(authState.instagram._id)}>Remove</button>
                                </div>
                            </div>
                        </span> :
                        <a className="text-decoration-none" href={`https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${!process.env.NODE_ENV || process.env.NODE_ENV === "development"
                            ? `https://localhost:3000${location.pathname}`
                            : `${window.location.origin}${location.pathname}`
                            }&scope=user_profile,user_media&response_type=code`}>
                            <div className="d-flex justify-content-center align-items-center text-center square-box" >
                                <div>
                                    <img src={InstagramIcon} className="img-fluid w-40" />
                                    <p className="mb-0 fw-medium mt-2">Connect to Instagram</p>
                                </div>
                            </div>
                        </a>}
            </div>
            <div className="col-lg-6 my-2 ">
                {tikTokLoginLoading ? <span className="text-decoration-none" >
                    <div className="d-flex justify-content-center align-items-center text-center square-box" >
                        <div>
                            <img src={TiktokIcon} className="img-fluid user mb-2" />
                            <p className="text-gray mb-0 fs-12">Connecting....</p>
                            <button type='button' className="btn-icon text-theme fs-12" ><Spinner /></button>
                        </div>
                    </div>
                </span> : authState.tiktok && authState.tiktok.user_name ?
                    <span className="text-decoration-none" >
                        <div className="d-flex justify-content-center align-items-center text-center square-box" >
                            <div>
                                <img src={authState.tiktok.profile_pic ? `${henceforthApi.FILES.imageMedium(authState.tiktok.profile_pic)}` : TiktokIcon} className="img-fluid user mb-2" />
                                <p className="fw-medium mb-0 text-black">{authState.tiktok.user_name} <span style={{ fontSize: '8px' }}>{`(${authState.tiktok.status})`}</span></p>
                                <p className="text-gray mb-0 fs-12">@{authState.tiktok.user_name}</p>
                                <button type='button' className="btn-icon text-theme fs-12" onClick={() => deleteTiktok(authState.tiktok._id)}>Remove</button>
                            </div>
                        </div>
                    </span> :
                    <div className="d-flex justify-content-center align-items-center text-center square-box cursor-pointer" onClick={setOpenTikTok} >
                        <div >
                            <img src={TiktokIcon} className="img-fluid w-40" />
                            <p className="mb-0 fw-medium mt-2" >Connect to Tiktok</p>
                        </div>
                    </div>}
            </div>
        </div>

        <button className="btn btn-primary w-100 text-uppercase py-2" onClick={() => goNextPage()}>CONTINUE</button>
        {!authState?.instagram?.user_name &&
            <Link to={`/signup/step-3`} onClick={() => setLoading(false)}><button className="btn btn-outline-primary w-100 text-uppercase py-2 fw-medium mt-2">SKIP</button></Link>}

        <Modals.TikTokRequest data={openTikTok} setData={setOpenTikTok} connectSocialAccount={connectTiktok} />

    </>
}
import { Link } from "react-router-dom";
import Medium from "./../../../assets/images/home/medium.svg";
import Instagram from "./../../../assets/images/home/instagram.svg";
import Telegram from "./../../../assets/images/home/telegram.svg";
import Twitter from "./../../../assets/images/home/twitter.svg";

const Footer = () => {
  return (
    <footer className="footer py-3">
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <h5 className="text-theme fw-bold">Everlens</h5>
            <p className="mb-0 fs-12">Discover new ways to create NFTs. Connect your social accounts or choose any pictures and mint,
              buy and sell one-of-a-kind digital items.</p>
          </div>
          <div className="col">
            <ul>
              <li><Link to="/how-it-works" className="text-dark text-decoration-none">How it works</Link></li>
              <li><Link to="/ELEN-token" className="text-dark text-decoration-none">ELEN Token</Link></li>
              <li><a href="https://snapshot.org/#/everlens1.eth" target={'_blank'} className="text-dark text-decoration-none">Governance</a></li>
            </ul>
          </div>
          <div className="col">
            <ul>
              <li> <a href="https://pancakeswap.finance/swap?inputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&outputCurrency=0xca2483727292ba552aec12dfee4dc105cb1376b9" target={`_blank`}>Buy ELEN</a></li>
              <li><Link to="/contact-us" className="text-dark text-decoration-none">Contact</Link></li>
            </ul>
          </div>
          <div className="col">
            <ul>
              <li><Link to="/terms-condition" className="text-dark text-decoration-none">Terms & Conditions</Link></li>
              <li><Link to="/privacy-policy" className="text-dark text-decoration-none">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col text-sm-center">
            <ul className="d-flex ps-0 justify-content-sm-center social-icon mb-2">
              <li>
                <a href="https://t.me/everlensnft" target="_blank">
                  <img src={Telegram} className="mx-1 icon" alt="Telegram" />

                </a>
              </li>
              <li>
                <a href="https://twitter.com/everlensnft?s=11" target="_blank">
                  <img src={Twitter} className="mx-1 icon" alt="Twitter" />

                </a>
              </li>
              <li>
                <a href="https://everlensio.medium.com/" target="_blank">
                  <img src={Medium} className="mx-1 icon" alt="Medium" />

                </a>
              </li>

              <li>
                <a href="https://www.instagram.com/everlensnft/" target="_blank">
                  <img src={Instagram} className="mx-1 icon" alt="Medium" />

                </a>
              </li>
            </ul>
            <p className="fs-12 mb-0">© Everlens, Inc. All rights reserved.</p>
          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;


import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NftPlayer, Spinner } from '../components/BootstrapCompo';
import Modals from '../components/Modals';
import NftPreview from '../components/nftCard/NftPreview';
import { EXPIRE_1_DAY, EXPIRE_3_DAY, EXPIRE_5_DAY, EXPLORE_NFT_TYPE_MARKETPLACE, FIXED_PRICE, NFT_STEP_1, PICK_SPECIFIC_DATE, RIGHT_AFTER_LISTING, TIMED_AUCTION } from '../context/actionTypes';
import { GlobalContext } from '../context/Provider';
import henceforthApi from '../utils/henceforthApi';
import henceforthValidations from '../utils/henceforthValidations';
import { toast } from 'react-toastify';
import { warningInstagramImport } from '../utils/work_contant';

export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);

    const { authState, setCreateNftStep, scrollToTop, categories, createNftStep, createNFT721, fileUpload, uploadFileIpfs } = useContext(GlobalContext)

    const drop = useRef(null);
    const [selectedFile, onSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [auctionType, setAuctionType] = useState(FIXED_PRICE)
    const [state, setState] = useState({
        tags: new Map(),
        tagArray: []
    })
    const [showStartDate, setShowStartDate] = useState(false)
    const [showEndDate, setShowEndDate] = useState(false)

    const [specificStartDate, setSpecificStartDate] = useState(new Date())
    const [specificEndDate, setSpecificEndDate] = useState(moment().add(1, 'days').valueOf())

    const [formData, setFormData] = useState({
        startDate: RIGHT_AFTER_LISTING,
        endDate: EXPIRE_1_DAY,
        price: "",
        royalties: "",
        quantity: "",
    })
    const [collectionData, setCollectionData] = useState({
        collection: [],
        total_count: null
    })

    const handleInput = ({ name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }
    henceforthApi.setToken(authState.access_token)

    const createNft = async () => {
        const { name, price, startDate, endDate, royalties, description, category_id, quantity } = formData

        if (!selectedFile) {
            return toast.warn("Please select file")
        }
        if (!Number(price)) {
            return toast.warn("Please enter valid amount")
        }
        if (!name) {
            return toast.warn("Please enter title")
        }
        if (!category_id) {
            return toast.warn("Select NFT category")
        }
        if (!description) {
            return toast.warn("Please enter caption")
        }
        if (Number(formData.royalties) > 10) {
            toast.warn("Royalties must be less then or equal to 10%")
            return
        }

        let start_date = 0;
        let end_date = 0;
        if (startDate == RIGHT_AFTER_LISTING) {
            start_date = moment.utc().valueOf()
        } else {
            start_date = Number(specificStartDate)
        }
        if (endDate == EXPIRE_1_DAY) {
            end_date = moment().add(1, 'days').utc().valueOf()
        } else if (endDate == EXPIRE_3_DAY) {
            end_date = moment().add(3, 'days').utc().valueOf()
        } else if (endDate == EXPIRE_5_DAY) {
            end_date = moment().add(5, 'days').utc().valueOf()
        } else {
            end_date = Number(specificEndDate)
        }
        if (startDate === PICK_SPECIFIC_DATE && endDate === PICK_SPECIFIC_DATE) {
            if (start_date > end_date) {
                return toast.warn("Invalid Date selected")
            }
        }
        setLoading(true)
        setCreateNftStep(NFT_STEP_1)
        let ipfsFilePath = await uploadFileIpfs(selectedFile)
        let videoUrlS3Bucket = await fileUpload(selectedFile)
        ipfsFilePath = `ipfs://${ipfsFilePath}`

        const collectionAddress = query.get("collection")
        const collection_id = query.get("collection_id")
        let metadataObject = {
            title: name,
            name,
            type: "object",
            description,
            image: ipfsFilePath,
            video: ipfsFilePath,
            properties: {
                name: {
                    type: "string",
                    description: name
                },
                description: {
                    type: "string",
                    description: description
                },
                image: {
                    type: "string",
                    description: "image desctiption now available"
                }
            }
        }
        try {
            const metadata = (await henceforthApi.Ipfs.metadata(JSON.stringify(metadataObject))).data
            const { token_id, token_address, erc, itemsVoucher } = await createNFT721(metadata, royalties, collectionAddress, auctionType, end_date, price)
            const { auction_type, signature, salt } = itemsVoucher
            debugger
            const nftItems = {
                erc,
                nft_type: EXPLORE_NFT_TYPE_MARKETPLACE,
                collection_id,
                category_id,
                token_id,
                token_address,
                quantity: quantity ? Number(quantity) : 1,
                name,
                description,
                file_url: videoUrlS3Bucket,
                file_ext: selectedFile.type,
                royalties: royalties ? royalties : 0,
                tags: state.tagArray,
                language: "ENGLISH"
            }

            let apiNftRes = await henceforthApi.NFT.create(nftItems)
            let data = apiNftRes.data
            debugger
            const voucherItems = {
                nft_id: data._id,
                auction_type,
                price: Number(price),
                quantity: quantity ? Number(quantity) : 1,
                start_date,
                end_date,
                signature,
                salt,
                language: "ENGLISH"
            }
            await henceforthApi.Voucher.create(voucherItems)
            navigate(`/token/${token_id}/${voucherItems.nft_id}/${auction_type == 1 ? 'trading' : 'bids'}/last_30_days`)
        } catch (error) {
            console.log("Error", error);
            debugger
        }
        setLoading(false)
    }

    const onAddTags = () => {
        let rowData = []
        let tag = formData.tag
        if (tag) {
            let map = state.tags
            map.set(tag, tag)

            map.forEach(element => {
                rowData.push(element)
            });
            setState({
                ...state,
                tags: map,
                tagArray: rowData
            })
            handleInput({ name: "tag", value: "" })
        }
    }

    const onRemoveTags = (tag) => {
        let rowData = []
        if (tag) {
            let map = state.tags
            map.delete(tag)

            map.forEach(element => {
                rowData.push(element)
            });
            setState({
                ...state,
                tags: map,
                tagArray: rowData
            })
        }
    }
    useEffect(() => {
        if (formData.startDate == PICK_SPECIFIC_DATE) {
            setShowStartDate(!showStartDate)
            if (formData.endDate == PICK_SPECIFIC_DATE) {
                setFormData({
                    ...formData,
                    endDate: EXPIRE_1_DAY
                })
            }
        } else {
            setSpecificStartDate(new Date())
        }

    }, [formData.startDate])
    useEffect(() => {
        if (formData.endDate == PICK_SPECIFIC_DATE) {
            setShowEndDate(!showEndDate)
        } else {
            setSpecificEndDate(moment().add(1, 'days').valueOf())
        }

    }, [formData.endDate])
    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
        return () => {
            if (drop.current) {
                drop.current.removeEventListener('dragover', handleDragOver);
                drop.current.removeEventListener('drop', handleDrop);
            }
        }
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            let file = files[0]
            if (String(file.type).startsWith("image") || String(file.type).startsWith("video")) {
                onSelectedFile(file)
            }
        }
    };
    const initialiseCollection = async (collection_id, collection_address) => {
        try {
            let apiRes = await henceforthApi.Collection.get("", 721, 0, 10)
            let data = apiRes.data
            setCollectionData(data)
            if (collection_id && collection_address) {
                query.set("collection_id", collection_id)
                query.set("collection", collection_address)
                navigate({ search: query.toString() }, { replace: true })
            } else {
                let collection = data?.collection
                if (Array.isArray(collection)) {
                    collection.forEach(element => {
                        if (element.default) {
                            query.set("collection_id", element._id)
                            query.set("collection", element.address)
                            navigate({ search: query.toString() }, { replace: true })
                        }
                    });
                }
            }
        } catch (error) {
            return null
        } finally {
            return
        }
    }

    useEffect(() => {
        if (authState.access_token) {
            if (authState.provider) {
                if (authState.email_verified) {
                    scrollToTop()
                    initialiseCollection()
                } else {
                    toast.warn("Please verify your email first.")
                    navigate(`/profile/edit?redirect=${location.pathname}`, { replace: true })
                }
            } else {
                toast.warn("Please connect wallet first.")
                navigate(`/wallet`, { replace: true })
            }
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [authState.access_token, authState.provider, authState.email_verified])
    return <Fragment>
        {!authState.v2_user && <div className="alert alert-warning" role="alert">
            {warningInstagramImport.subtitle}
        </div>}<section className="edit-profile   mb-5 pt-md-5 pt-4 pb-5">
            <div className="container">
                <div className="post-content">
                    <div className="row">
                        <div className="col-xl-7 col-lg-6">
                            <h2 className="fw-bold mb-3"><span className="text-gradient">Create Single New Post</span></h2>

                            <div className="profile">
                                <div className="form-group mb-3" ref={drop}>

                                    <label className="fw-medium mb-1" htmlFor="">Upload File</label>
                                    {selectedFile ?
                                        <div className="border-dashed rounded-3 d-block position-relative text-center">
                                            <button type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0 cross" onClick={() => onSelectedFile(null)}><i className="fa-solid fa-xmark"></i></button>
                                            <div className="upload-player"><NftPlayer selectedFile={selectedFile} /></div>
                                        </div> :
                                        <label className="text-center p-3 border-dashed rounded-3 d-block ">
                                            <input type="file" className="d-none" accept='image/*, video/*' onChange={(e) => onSelectedFile(e.target.files[0])} />
                                            <span className="btn btn-primary fs-12">CHOOSE FILE</span>
                                        </label>}

                                </div>

                                <ul className="nav nav-pills mb-3 common-tabs" id="pills-tab" role="tablist">
                                    <li className="nav-item w-100" role="presentation">
                                        <button className="nav-link active  w-100 fs-12 " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setAuctionType(FIXED_PRICE)}>FIXED PRICE</button>
                                    </li>
                                    <li className="nav-item w-100" role="presentation">
                                        <button className="nav-link  w-100 mt-2 fs-12" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setAuctionType(TIMED_AUCTION)}>TIMED AUCTION</button>
                                    </li>

                                </ul>
                                <div className="form-group mb-3">
                                    <label className="fw-medium mb-1" htmlFor="">{auctionType == FIXED_PRICE ? 'Price' : 'Floor Price'}</label>
                                    <div className="position-relative">
                                        <input type="text" className="form-control border-pink border pe-5"
                                            placeholder='e.g. "0.5 ELEN" ' name="price" value={formData.price} onChange={(e) => handleInput(e.target)} />
                                        <span className="fw-medium fs-12 position-absolute top-50 translate-middle-y end-0 me-3">ELEN</span>
                                    </div>
                                </div>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                    </div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className="row mb-3">
                                            <div className="col-md-6 pe-1">
                                                <div className="form-group mb-1">
                                                    <label className="fw-medium mb-1" htmlFor="">Starting Date</label>
                                                    <select className="form-select fs-14 border-pink" value={formData.startDate} name="startDate" aria-label="start date select" onChange={(e) => handleInput(e.target)}>
                                                        <option value={RIGHT_AFTER_LISTING}>Right After Listing</option>
                                                        <option value={PICK_SPECIFIC_DATE}>Pick Specific Date</option>
                                                    </select>
                                                </div>
                                                {formData.startDate == PICK_SPECIFIC_DATE ?
                                                    <span className="fs-12 tags me-2 fw-medium mt-2 ">{moment(specificStartDate).format("DD/MM/YYYY")}
                                                        <button className="btn-icon" >
                                                            <i className="fa-solid fa-xmark ms-1" onClick={() => handleInput({ name: 'startDate', value: RIGHT_AFTER_LISTING })}></i>
                                                        </button>
                                                    </span>
                                                    : ''}
                                            </div>
                                            <div className="col-md-6 ps-1">
                                                <div className="form-group mb-1">
                                                    <label className="fw-medium mb-1" htmlFor="">Expiration Date</label>
                                                    <select className="form-select fs-14 border-pink" value={formData.endDate} name="endDate" onChange={(e) => handleInput(e.target)}>
                                                        <option value={EXPIRE_1_DAY}>1 Day</option>
                                                        <option value={EXPIRE_3_DAY}>3 Days</option>
                                                        <option value={EXPIRE_5_DAY}>5 Days</option>
                                                        <option value={PICK_SPECIFIC_DATE}>Pick Specific Date</option>
                                                    </select>
                                                </div>
                                                {formData.endDate == PICK_SPECIFIC_DATE ?
                                                    <span className="fs-12 tags me-2 fw-medium mt-2 ">{moment(specificEndDate).format("DD/MM/YYYY")}
                                                        <button className="btn-icon">
                                                            <i className="fa-solid fa-xmark ms-1" onClick={() => handleInput({ name: 'endDate', value: RIGHT_AFTER_LISTING })}></i>
                                                        </button>
                                                    </span>
                                                    : ''}

                                            </div>
                                            {/* <div className="col-md-12">
                                            <p className=" fs-12 fw-semi-bold text-gray mt-2">Any Bid Placed In The Last 10 Minutes Extends The Auction By 10 Minutes.</p>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className='fw-medium mb-1'>Choose Collection</label>
                                        <div className="d-flex align-items-center flex-wrap">
                                            <button className="col-box btn-icon mb-3" data-bs-toggle="modal" data-bs-target="#createCollectionModal">
                                                <i className="fa-solid fa-circle-plus "></i>
                                                <p className="mb-0 fw-bold">Create</p>
                                                <p className="text-gray fs-12 mb-0 line-clamp-1">ERC-721</p>
                                            </button>
                                            {Array.isArray(collectionData.collection) && collectionData.collection.map(res =>
                                                <div className={`col-box  mb-3 ${res._id === query.get("collection_id") ? 'active' : ''}`} onClick={() => {
                                                    query.set("collection_id", res._id)
                                                    query.set("collection", res.address)
                                                    navigate({ search: query.toString() })
                                                }}>
                                                    <img src={henceforthApi.FILES.imageMedium(res.img_url)} className={res.default ? 'w-35' : 'c-img'} />
                                                    <p className="mb-0 fw-bold line-clamp-1 text-break">{res.name}</p>
                                                    <p className="text-gray fs-12 mb-0 line-clamp-1 text-break">{res.symbol}</p>
                                                </div>)}
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Title</label>
                                        <input type="text" className="form-control border-pink border"
                                            placeholder="Title" name="name" value={formData.name} onChange={(e) => handleInput(e.target)} />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Select Category</label>
                                        <select className="form-select fs-14 border-pink" name="category_id" value={formData.category_id} onChange={(e) => handleInput(e.target)}>
                                            <option value={""}>Select Category</option>
                                            {categories.map(res => { return <option value={res._id}>{res.name}</option> })}

                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Caption (max 150 characters)</label>
                                        <textarea type="text" className="form-control border-pink border caption fw-normal"
                                            placeholder="Caption" name="description" value={formData.description} onChange={(e) => handleInput(e.target)}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="fw-medium mb-1" htmlFor="">Royalties</label>
                                        <div className="position-relative">
                                            <input type="number" className="form-control border-pink border pe-5"
                                                placeholder="Royalties" name='royalties' value={formData.royalties} min={0} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? handleInput(e.target) : console.log(e.target.value)} autoComplete="off" />
                                            <span className="fw-medium fs-12 position-absolute top-50 translate-middle-y end-0 me-3">%</span>
                                        </div>
                                        <p className="mb-0 fs-12 fw-semi-bold text-gray mt-2">Suggested: Max 10% allowed.</p>
                                    </div>

                                    <div className="form-group mb-3">
                                        <form onSubmit={(e) => { e.preventDefault(); onAddTags() }}>
                                            <label className="fw-medium mb-1" htmlFor="">Tags (Optional)</label>
                                            <div className="position-relative">
                                                <input type="text" className="form-control border-pink border pe-5"
                                                    placeholder="Tag" name='tag' value={formData.tag} onChange={(e) => handleInput(e.target)} />
                                                <button type='submit' className="fw-medium fs-12 btn-icon position-absolute top-50 translate-middle-y end-0 me-3">Add</button>
                                            </div>
                                            <div className="mt-2">
                                                {state.tagArray.map(res => {
                                                    return <span className="fs-12 tags me-2 mb-2 fw-medium">{res} <button className="btn-icon"><i className="fa-solid fa-xmark ms-1" onClick={() => onRemoveTags(res)}></i></button></span>
                                                })}
                                            </div>
                                        </form>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-4 pt-2">
                                    <button type="button" className="btn btn-primary shadow-none rounded-pill w-100 me-2 fs-12" onClick={createNft} disabled={loading}>{loading ? <Spinner /> : 'MINT NOW'}</button>
                                    <button type="button"
                                        className="btn btn-outline-primary shadow-none rounded-pill w-100 fw-medium fs-12" onClick={() => window.history.back()}>CANCEL</button>
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-5  col-lg-6 mt-lg-0 mt-4">
                            <div className="preview-box-content rounded-3 border-dashed">
                                <h6 className="fw-medium mb-3">Preview</h6>
                                <div className="preview-box rounded-3 position-relative">
                                    <div className="multiple-preview w-100">
                                        <NftPreview selectedFile={selectedFile} {...formData} nftType="marketplace" elenToUsd={0} authState={authState} contentType={auctionType} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modals.StartDatePicker data={showStartDate} setData={setShowStartDate} onChange={setSpecificStartDate} />
            <Modals.EndDatePicker data={showEndDate} setData={setShowEndDate} onChange={setSpecificEndDate} minDate={specificStartDate} />
            <Modals.NftCreateStep createNftStep={createNftStep} />
            <Modals.CreateCollection erc={721} callback={initialiseCollection} />

        </section>
    </Fragment>
}
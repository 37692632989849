import React, { Fragment, useEffect, useState } from "react"
import { Web3Provider } from '@ethersproject/providers';
import snapshot from '@snapshot-labs/snapshot.js';
import henceforthApi from "../../utils/henceforthApi";
import { Avatar, Button, Col, Input, List, Modal, Row, Space, Tooltip, Typography } from "antd";
import { CheckCircleTwoTone, LikeOutlined, MessageOutlined, RadarChartOutlined, StarOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

const client = new snapshot.Client712(henceforthApi.SNAPSHOT_HUB_API_ROOT);
const { TextArea } = Input;
const ProposalsPage = () => {
    const web3 = new Web3Provider(window.ethereum);
    const [proposals, setProposals] = useState([])
    const [voteOptions, setVoteOptions] = useState({
        id: "",
        app: "",
        choices: [],
        type: "",
        space: { id: "", name: "" }
    })
    const [reason, setReason] = useState("")

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);


    const showModal = (item) => {
        setVoteOptions(item)
        setOpen(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        const [account] = await web3.listAccounts();
        try {
            const choice = voteOptions.choices.find((res) => res.selected === true)
            const items = {
                space: voteOptions.space.id,
                proposal: voteOptions.id,
                type: voteOptions.type,
                choice: choice.index + 1,
                reason: reason,
                app: voteOptions.app
            }
            console.log('items', items);
            const receipt = await client.vote(web3, account, items);

            setOpen(false);
            console.log('receipt', receipt);
        } catch (error) {
            console.log('createVote error', error);
        } finally {
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const IconText = ({ icon, text }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    const initialise = async () => {
        try {
            const items = {
                "query": "query Proposals {\n  proposals (\n    first: 20,\n    skip: 0,\n    where: {\n      space_in: [\"hammim.eth\"],\n    },\n    orderBy: \"created\",\n    orderDirection: desc\n  ) {\n    id\n    title\n    body\n    app\n    choices\n     type\n    start\n    end\n    snapshot\n    state\n    author\n    space {\n      id\n      name\n    }\n  }\n}",
                "variables": null,
                "operationName": "Proposals"
            }
            const apiRes = await henceforthApi.Snapshot.proposals(items)
            const data = apiRes.data
            setProposals(data.proposals)
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        initialise()
    }, [])

    return <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <List
            itemLayout="vertical"
            size="large"
            // pagination={{
            //     onChange: (page) => {
            //         console.log(page);
            //     },
            //     pageSize: 1,
            // }}
            dataSource={proposals}
            header={
                <div>
                    <Row justify={'space-between'} gutter={[10, 10]}>
                        <Col>Proposals</Col>
                        <Col><Link to={`/proposal/create`}><Button >Create proposal</Button></Link></Col>
                    </Row>
                </div>
            }
            renderItem={(item) => (
                <List.Item
                    key={item.id}
                    actions={[
                        <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                        <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                        <Tooltip title="Create Vote"><RadarChartOutlined onClick={() => showModal(item)} /></Tooltip>,
                    ]}
                    extra={
                        <img
                            width={272}
                            alt="logo"
                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                        />
                    }
                >
                    <List.Item.Meta
                        avatar={<Avatar src={item.avatar} />}
                        title={<a href={item.href}>{item.id}</a>}
                        description={item.title}
                    />
                    {item.body}
                </List.Item>
            )}
        />
        <Modal
            title="Cast your vote"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            okText="Vote"
            onCancel={handleCancel}
        >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <List
                    bordered
                    dataSource={voteOptions.choices}
                    renderItem={(item, index) => (
                        <List.Item onClick={() => {
                            const choices = voteOptions.choices.map((res, index2) => {
                                if (voteOptions.type == "single-choice") {
                                    if (index === index2) {
                                        return {
                                            title: typeof res == "string" ? res : res.title,
                                            index: index2,
                                            selected: true
                                        }
                                    } else {
                                        return {
                                            title: typeof res == "string" ? res : res.title,
                                            index: index2,
                                            selected: false
                                        }
                                    }
                                } else {
                                    if (index === index2) {
                                        return {
                                            title: typeof res == "string" ? res : res.title,
                                            index: index2,
                                            selected: !res.selected
                                        }
                                    } else {
                                        return {
                                            title: typeof res == "string" ? res : res.title,
                                            index: index2,
                                            selected: res.selected
                                        }
                                    }
                                }
                            })
                            console.log('choices', choices);

                            setVoteOptions({
                                ...voteOptions,
                                choices
                            })
                        }}
                            actions={[<>{item.selected && <CheckCircleTwoTone />}</>]}
                        >
                            {typeof item == "string" ?
                                <Typography.Text >{item} </Typography.Text> :
                                <Typography.Text > {item?.title}</Typography.Text>}

                        </List.Item>
                    )}
                />
                <TextArea showCount maxLength={100} placeholder="Reason" value={reason} onChange={(e) => setReason(e.target.value)} />
            </Space>
        </Modal>
    </Space>
}
export default ProposalsPage
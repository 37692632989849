import henceforthApi from "../utils/henceforthApi"
import { Spinner } from "./BootstrapCompo"

export default ({ file_ext, file_url }) => {
    if (String(file_ext).toLowerCase().toLowerCase().includes("video")) {
        return <video className="w-100 bid-image" controls autoPlay muted loop playsInline> <source src={henceforthApi.FILES.video(file_url)}></source></video>
    } else if (file_ext || file_url) {
        return (
            <>
                <div data-bs-toggle="modal" data-bs-target="#detials"> <img src={henceforthApi.FILES.imageOriginal(file_url)} alt={henceforthApi.FILES.imageMedium(file_url)} className="w-100 bid-image" /></div>
                <div className="modal fade" id="detials" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog colorbox-modal modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <button type="button" className="btn border-0  shadow-none fs-6 position-absolute  z-index-1 close-icon" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
                                <img src={henceforthApi.FILES.imageOriginal(file_url)} alt={henceforthApi.FILES.imageMedium(file_url)} className="w-100 large-bid-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else return <div className="center-loader"><Spinner /></div>

}



export const Spinner = (props) => {

    // return <div className={`spinner-grow spinner-grow-sm `} role="status">
    //     <span className="visually-hidden"></span>
    // </div>
    return <div className="spinner-border spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
}


export const GlobalLoading = () => {
    return <div className="modal fade steps show" id="exampleModalLive" tabIndex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog" style={{ display: "block", zIndex: 10, backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog modal-dialog-centered loader-modal">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="nft-body-modal">
                        <div className="upload-stepss text-center">
                            <div className="step-img">
                                {/* <Spinner /> */}
                                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                            <div className="step-text mt-4">
                                <h2>Loading...</h2>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
}
export const NftPlayer = ({ selectedFile }) => {
    return selectedFile ? <>{String(selectedFile.type).includes("image") ?
        <img src={URL.createObjectURL(selectedFile)} className="w-100 uploaded-images" /> :
        String(selectedFile.type).includes("video") ?
            <video controls autoPlay muted loop playsInline> <source src={URL.createObjectURL(selectedFile)} className="w-100 " ></source></video> :
            <audio className="w-100 " controls autoPlay muted loop src={URL.createObjectURL(selectedFile)} > </audio>
    }</> : <div className="position-absolute top-50 start-50 translate-middle"><b >Upload file to preview your brand new NFT</b></div>
}
export const InstagramPlayer = ({ id, media_url, media_type, caption, selectedFile }) => {
    return media_url ? String(media_type).includes("image") ?
        <img src={media_url} className={`w-100 uploaded-images ${selectedFile ? selectedFile.id == id ? 'selected_media' : '' : ''} `} /> :
        String(media_type).includes("CAROUSEL_ALBUM") ?
            <img src={media_url} className={`w-100 uploaded-images ${selectedFile ? selectedFile.id == id ? 'selected_media' : '' : ''} `} /> :
            <video
                src={media_url}
                onMouseOver={(event) => event.target.play()}
                onMouseOut={(event) => event.target.pause()}
                className={`w-100 ${selectedFile ? selectedFile.id == id ? 'selected_media' : '' : ''} `}
                controls
                muted
                loop
                playsInline
            >
            </video>
        : <div className="position-absolute top-50 start-50 translate-middle"><b >Upload file to preview your brand new NFT</b></div>
}
export const TiktokPlayer = ({ id, playAddr, media_type, caption, selectedFile }) => {
    return playAddr ? <video
        src={playAddr}
        onMouseOver={(event) => event.target.play()}
        onMouseOut={(event) => event.target.pause()}
        className={`w-100 uploaded-images ${selectedFile ? selectedFile.id == id ? 'selected_media' : '' : ''} `}
        controls
        muted
        loop
        playsInline
    >
    </video>
        : <div className="position-absolute top-50 start-50 translate-middle"><b >Upload file to preview your brand new NFT</b></div>
}

import { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import profile_placeholder from "../assets/images/home/img_placeholder.svg"
import { Spinner } from "../components/BootstrapCompo";
import authAction from "../context/actions/auth/authAction";
import { GlobalContext, INSTAGRAM_CLIENT_ID } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import InstagramIcon from "../assets/images/instagram_img.png";
import TiktokIcon from "../assets/images/home/tiktok_small.svg";
import { getPrimaryWalletType } from "../utils/work_contant";
import Modals from "../components/Modals";
import { ERROR_UNAUTHORIZED } from "../context/actionTypes";
import henceforthValidations from "../utils/henceforthValidations";

var countdownTimer = null;
export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { authState, accounts, authDispatch, scrollToTop, fileUpload, loading, setLoading, connectTiktok, tikTokLoginLoading } = useContext(GlobalContext)

    const [state, setState] = useState({
        ...authState,
        loading: false
    })
    const [timerCount, setTimerCount] = useState(30)
    const [openTikTok, setOpenTikTok] = useState(false)
    const [tiktokCloseModal, setTiktokCloseModal] = useState(null)
    const [tiktokLoading, setTiktokLoading] = useState(false)
    henceforthApi.setToken(authState.access_token)

    const handleInput = ({ name, value }) => {
        if (name == "name") {
            if (henceforthValidations.nameValidation(value)) {
                setState({
                    ...state,
                    [name]: value,
                    loading: false
                })
            }

        } else {

            setState({
                ...state,
                [name]: value,
                loading: false
            })
        }
    }

    const updateProfile = async (items, redirect) => {
        setLoading(true)
        try {
            let apiRes = await henceforthApi.Profile.edit(items)
            let data = apiRes.data
            let walletType = getPrimaryWalletType(data.wallet)
            setLoading(false)
            authAction.initialiseProfile({ ...data, walletType })(authDispatch);

            setState({
                ...state,
                loading: false,
                loading_profile_icon: false,
            })
            if (redirect) {
                navigate(`/profile/on_sale`)
            }
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.body) {
                if (error.response.body.error == ERROR_UNAUTHORIZED) {
                    navigate(`/signin?redirect=${location.pathname}`, { replace: true })
                } else {
                    toast.error(error.response.body.error_description)
                }
            }
            setLoading(false)
            setState({
                ...state,
                loading: false,
                loading_profile_icon: false,
            })
        }
    }

    const onUpdateInputField = async () => {
        const { user_name, email, name } = state
        let items = {
            user_name: String(user_name).trim(),
            name: name,
            email: email,
            language: "ENGLISH"
        }
        await updateProfile(items, true)
    }
    const changeEmail = async () => {
        if (!loading) {
            const { email } = state
            if (email) {
                if (henceforthValidations.email(email)) {

                    let items = {
                        email: email,
                        language: "ENGLISH"
                    }
                    await updateProfile(items, false)
                } else {
                    toast.warn("Invalid email address")
                }
            } else {
                toast.warn("Please enter email address")
            }
        }
    }

    const onUpdateProfilePicture = async (file) => {
        setState({
            ...state,
            loading_profile_icon: true
        })
        let fileName = file ? await fileUpload(file) : ""
        let items = {
            profile_pic: fileName,
            language: "ENGLISH"
        }
        await updateProfile(items, false)
    }

    const resendOtp = async () => {
        try {
            let items = {
                email: authState.email,
                language: "ENGLISH"
            }
            let apiRes = await henceforthApi.Auth.resendOtp(items)
            toast.success(apiRes.message)
            initialiseInterval()
            return apiRes.data
        } catch (error) {
            if (error.response && error.response.body) {
                toast.error(error.response.body.error_description)
            }
        }
    }
    const initialiseInterval = () => {
        debugger
        var _second = 30
        function timer() {
            if (_second < 1) {
                clearInterval(countdownTimer);
                countdownTimer = null
                return;
            } else {
                _second -= 1
            }
            setTimerCount(_second)
        }
        countdownTimer = setInterval(() => timer(), 1000);
        debugger
    }

    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token])

    const openVerifyEmailLink = async (email) => {
        debugger
        if (!authState.email_verified) {
            let apiRes = await henceforthApi.Auth.resendOtp({
                email: email,
                language: "ENGLISH"
            })
        }
        debugger
        if (countdownTimer == null) {
            initialiseInterval()
        }
    }
    const initialiseProfile = async () => {
        try {
            let apiRes = await henceforthApi.Profile.get()
            let data = apiRes.data
            let walletType = getPrimaryWalletType(data.wallet)
            setLoading(false)
            authAction.initialiseProfile({ ...data, walletType })(authDispatch);
        } catch (error) {
            if (error.response && error.response.body) {
                toast.error(error.response.body.error_description)
            }
        }
    }

    const connectInstagram = async () => {
        let code = query.get("code")
        try {
            let redirect_uri = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
                ? `https://localhost:3000${location.pathname}`
                : `${window.location.origin}${location.pathname}`

            let items = {
                type: "INSTAGRAM",
                code,
                language: "ENGLISH",
                redirect_uri
            }
            let apiRes = await henceforthApi.Auth.connectSocialAccount(items)
            let data = apiRes.data
            let walletType = getPrimaryWalletType(data.wallet)
            setLoading(false)
            authAction.initialiseProfile({ ...data, walletType })(authDispatch);
            navigate(`/profile/edit`, { replace: true })
        } catch (error) {
            if (error.response && error.response.body) {
                toast.error(error.response.body.error_description)
                navigate(`/profile/edit`, { replace: true })
            }
        }
    }
    const initialiseEmailVerify = (data) => {
        authAction.initialiseProfile({ ...data })(authDispatch);
    }
    const deleteInstagram = async (id) => {
        try {
            debugger
            let apiRes = await henceforthApi.Profile.deleteSocial(id)
            let data = apiRes.data
            authAction.deleteInstagram({ ...data, })(authDispatch);
            toast.success(apiRes.message)
        } catch (error) {
            console.log("error", error);
            debugger
        }
    }
    const deleteTiktok = async (id) => {
        try {
            setTiktokLoading(true)
            let apiRes = await henceforthApi.Profile.deleteSocial(id)
            let data = apiRes.data
            authAction.deleteTiktok({ ...data, })(authDispatch);
            setTiktokCloseModal(null)
            toast.success(apiRes.message)
        } catch (error) {
            console.log("error", error);
            debugger
        } finally {
            setTiktokLoading(false)
        }
    }

    useEffect(() => {
        if (query.has("code")) {
            connectInstagram()
        }
    }, [query.get("code")])

    useEffect(() => {
        initialiseProfile()
    }, [])

    return <section className="edit-profile mb-5 pt-md-5 pt-4 pb-5">
        <div className="container">
            <form className="" onSubmit={(e) => { e.preventDefault(); onUpdateInputField() }}>
                <div className="row justify-content-around">
                    <div className="col-xl-4 col-lg-5 col-md-6">
                        <div className="">

                            <h2 className="fw-bold mb-3"><span className="text-gradient">Edit Profile</span></h2>

                            <div className="form-group mb-3">
                                <label className="fw-medium mb-1" htmlFor="">Name</label>
                                <input type="text" className="form-control border-pink border"
                                    placeholder="Enter your name" aria-label="Recipient's username"
                                    aria-describedby="basic-addon2" name="name" value={state.name} onChange={(e) => handleInput(e.target)} required />
                            </div>
                            <div className="form-group mb-3">
                                <label className="fw-medium mb-1" htmlFor="">Email</label>
                                <div className="position-relative">
                                    <input type="text" className="form-control border-pink border pe-5"
                                        placeholder="Enter your email" aria-label="Recipient's username"
                                        aria-describedby="basic-addon2" name="email" value={state.email} onChange={(e) => handleInput(e.target)} required />
                                    {state.email !== authState.email ?
                                        <span className={`text-theme fs-12 position-absolute top-50 translate-middle-y end-0 me-3 cursor-pointer`} onClick={() => changeEmail()} >{loading ? <Spinner /> : 'Update'}</span> :
                                        <span className={`text-theme fs-12 position-absolute top-50 translate-middle-y end-0 me-3 ${authState.email_verified ? '' : 'cursor-pointer'}`} data-bs-toggle="modal"
                                            data-bs-target={authState.email_verified ? '' : '#VerifyEmailModal'} onClick={() => openVerifyEmailLink(authState.email)}>{authState.email_verified ? 'Verified' : 'Verify Now'}</span>}
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label className="fw-medium mb-1" htmlFor="">Username</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">@</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" name="user_name" value={String(state.user_name).trim()} onChange={(e) => handleInput(e.target)} required />
                                </div>
                            </div>

                            <div className="form-group mb-2">
                                <label className="fw-medium mb-1" htmlFor="">Instagram Id</label>
                                {query.has("code") ?
                                    <div className="border border-pink p-3 rounded-2 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <img src={InstagramIcon} className="user" />
                                            <div className="ms-2">
                                                <p className="fw-medium mb-0">Connecting...</p>
                                            </div>
                                        </div>
                                        <button type="button" className="text-theme btn-icon fs-12"><Spinner /></button>
                                    </div> :
                                    authState.instagram && authState.instagram.user_name ?
                                        <div className="border border-pink p-3 rounded-2 d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <img src={authState.instagram.profile_pic ? `${henceforthApi.FILES.imageMedium(authState.instagram.profile_pic)}` : InstagramIcon} className="user" />
                                                <div className="ms-2">
                                                    <p className="fw-medium mb-0">{authState.instagram.user_name}</p>
                                                    <p className="text-gray fs-12 mb-0">@{authState.instagram.user_name}</p>
                                                </div>
                                            </div>
                                            <button type="button" className="text-danger btn-icon fs-12" onClick={() => deleteInstagram(authState.instagram._id)}>Remove</button>
                                        </div> :
                                        <div className="border border-pink p-3 rounded-2 d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <img src={InstagramIcon} className="user" />
                                                <div className="ms-2">
                                                    <p className="fw-medium mb-0">Instagram</p>
                                                </div>
                                            </div>
                                            <a className="text-theme btn-icon fs-12" href={`https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${!process.env.NODE_ENV || process.env.NODE_ENV === "development"
                                                ? `https://localhost:3000${location.pathname}`
                                                : `${window.location.origin}${location.pathname}`
                                                }&scope=user_profile,user_media&response_type=code`}>Connect</a>

                                        </div>}
                            </div>

                            <div className="form-group mb-2">
                                <label className="fw-medium mb-1" htmlFor="">Tiktok Id</label>
                                {tikTokLoginLoading ? <div className="border border-pink p-3 rounded-2 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <img src={TiktokIcon} className="user" />
                                        <div className="ms-2">
                                            <p className="fw-medium mb-0">Connecting...</p>
                                        </div>
                                    </div>
                                    <button type="button" className="text-theme btn-icon fs-12"><Spinner /></button>
                                </div> :
                                    authState.tiktok && authState.tiktok.user_name ?
                                        <div className="border border-pink p-3 rounded-2 d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <img src={authState.tiktok.profile_pic ? `${henceforthApi.FILES.imageMedium(authState.tiktok.profile_pic)}` : TiktokIcon} className="user" />
                                                <div className="ms-2">
                                                    <p className="fw-medium mb-0">{authState.tiktok.user_name} <span style={{ fontSize: '8px' }}>{`(${authState.tiktok.status})`}</span></p>
                                                    <p className="text-gray fs-12 mb-0">@{authState.tiktok.user_name}</p>
                                                </div>
                                            </div>
                                            <div>
                                                {authState.tiktok.status === "PENDING" &&
                                                    <button type="button" className="text-theme btn-icon fs-12 me-2" onClick={() => setOpenTikTok(authState.tiktok)}>View</button>}
                                                <button type="button" className="text-danger btn-icon fs-12" onClick={() => setTiktokCloseModal({ info: authState?.tiktok?._id, title: 'Tiktok', subtitle: 'Are you sure want to remove Tiktok?' })}>Remove</button>
                                            </div>
                                        </div> :
                                        <div className="border border-pink p-3 rounded-2 d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <img src={TiktokIcon} className="user" />
                                                <div className="ms-2">
                                                    <p className="fw-medium mb-0">Tiktok</p>
                                                </div>
                                            </div>
                                            {/* <a className="text-theme btn-icon fs-12" href={`https://www.tiktok.com/auth/authorize?client_key=${INSTAGRAM_CLIENT_ID}&redirect_uri=${!process.env.NODE_ENV || process.env.NODE_ENV === "development"
                                            ? `https://localhost:3000${location.pathname}`
                                            : `${window.location.origin}${location.pathname}`
                                            }&scope=user_profile,user_media&response_type=code`}>Connect</a> */}
                                            {/* <a className="text-theme btn-icon fs-12" href="#" onClick={() => connectTiktok()}>Connect</a> */}
                                            <button type="button" className="text-theme btn-icon fs-12" onClick={setOpenTikTok}>Connect</button>
                                        </div>}

                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-4 pt-2">
                                <button type="submit" className="btn btn-primary shadow-none rounded-pill w-100 me-2 fs-12" disabled={loading}>{loading ? <Spinner /> : 'APPLY CHANGES'}</button>
                                <button type="button" className="btn btn-outline-primary shadow-none rounded-pill w-100 fw-medium  fs-12" onClick={() => navigate(`/profile/on_sale`)}>CANCEL</button>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 mt-md-0 mt-4">
                        <div className="border border-pink p-4 rounded-3 text-center">
                            <label>
                                <label className="profile-image position-relative">
                                    <img src={authState.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.profile_pic}` : profile_placeholder} className="border-0" />
                                    {/* {authState.profile_pic && <div className="delete-icon" onClick={() => onUpdateProfilePicture(null)}><i class="fa-solid fa-trash"></i></div>} */}
                                </label>
                                <div className="mt-4">
                                    <div className="btn btn-primary fs-12" >{state.loading_profile_icon ? <Spinner /> : 'CHANGE PHOTO'}</div>
                                </div>
                                <input type="file" className="d-none" accept="image/*" onChange={(e) => onUpdateProfilePicture(e.target.files[0])} disabled={state.loading_profile_icon} />

                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <Modals.VerifyEmail initialiseProfile={initialiseEmailVerify} resendOtp={resendOtp} timerCount={timerCount} />
        <Modals.Confirm key="Modals.Confirm" data={tiktokCloseModal} setData={setTiktokCloseModal} onSubmit={deleteTiktok} loading={tiktokLoading} />
        <Modals.TikTokRequest data={openTikTok} setData={setOpenTikTok} connectSocialAccount={connectTiktok} />
    </section>
}
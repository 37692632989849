import Placeholder from "../assets/images/home/img_placeholder_small.svg";
import All from "../assets/images/icons/all.png";
import CoverImg from "../assets/images/collection/cover.png"
import Twitter from "../assets/images/icons/twitter.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import Email from "../assets/images/icons/email.svg";
import Copy from "../assets/images/icons/copy.svg";

import InstagramIcon from "../assets/images/instagram_img.png";
import TiktokIcon from "../assets/images/home/tiktok_small.svg";
import Single from "../assets/images/icons/single_small.png"
import Multiple from "../assets/images/icons/multiple_small.png"

import { useLocation, useMatch, useNavigate } from "react-router-dom";
import Listing from "../assets/images/collection_activity/listing.svg";
import Burn from "../assets/images/collection_activity/burn.svg";
import Like from "../assets/images/collection_activity/like.svg";
import Purchase from "../assets/images/collection_activity/purchase.svg";
import Sale from "../assets/images/collection_activity/sale.svg";
import Transfer from "../assets/images/collection_activity/transfer.svg";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { toast } from "react-toastify";
import { GlobalContext } from "../context/Provider";
import NftCard from "../components/nftCard";
import moment from "moment";
import Modals from "../components/Modals";
import { subscribeFormatter } from "../utils/work_contant";
import { EXPLORE_LANDING_MARKET_PLACE, EXPLORE_LANDING_SOCIAL, EXPLORE_NFT_POST_TYPE_MULTIPLE, EXPLORE_NFT_POST_TYPE_SINGLE, EXPLORE_NFT_TYPE_INSTAGRAM, EXPLORE_NFT_TYPE_TIKTOK } from "../context/actionTypes";

export default () => {
  let match = useMatch("/collection/:id/:tab")
  const navigate = useNavigate()
  const location = useLocation()
  var query = new URLSearchParams(location.search);

  const { authState, staticState, elenToUsd, categories, fileUpload } = useContext(GlobalContext)

  const [maxPrice, setMaxPrice] = useState(0)
  const [minPrice, setMinPrice] = useState(0)
  const [reportData, setReportData] = useState(null)

  const [state, setState] = useState({
    address: "",
    cover_img: "",
    created_by: { _id: '', profile_pic: '', name: '', user_name: '' },
    img_url: "",
    items_count: 1,
    name: "",
    total_volume: 0,
  })
  const [nfts, setNfts] = useState({
    items: [],
  })
  const [activitiesData, setActivitiesData] = useState({
    activities: [],
    total_count: 0
  })

  const updateCollection = async (items) => {
    try {
      let apiRes = await henceforthApi.Collection.update({ ...items, _id: match.params.id })
      await initialiseData()
    } catch (error) {
      toast.error(error?.response?.body?.error_desctiption)
    }

  }
  const addToIpfs = async (file) => {
    return await fileUpload(file)
  }

  const setCollectionIcon = async (file) => {
    let img_url = await addToIpfs(file)
    let items = {
      img_url,
      language: "ENGLISH"
    }
    await updateCollection(items)
  }

  const setCollectionBanner = async (file) => {
    let cover_img = await addToIpfs(file)
    let items = {
      cover_img,
      language: "ENGLISH"
    }
    await updateCollection(items)
  }

  const initialiseFev = async (index, _id, is_favourite) => {
    if (authState.access_token) {
      try {
        let items = {
          nft_id: _id,
          language: "ENGLISH"
        }
        let data = []
        data = nfts.items.filter(res => res._id === _id)
        if (data.length) {
          nfts.items[index]["is_favourite"] = is_favourite
          if (is_favourite) {
            nfts.items[index]["total_favourites"] = data[0].total_favourites + 1
          } else {
            nfts.items[index]["total_favourites"] = data[0].total_favourites - 1
          }
          setNfts({ ...nfts })
        }
        if (is_favourite) {
          await henceforthApi.NFT.addToFev(items)
        } else {
          await henceforthApi.NFT.removeFromFev(items)
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const initialiseData = async () => {
    var query = new URLSearchParams();
    query.append('nft_type', staticState.explore)
    let q = query.toString()
    debugger
    try {
      let apiRes = await henceforthApi.Collection.getById(match.params.id, q)
      let data = apiRes.data
      setState(data)
    } catch (error) {

    }
  }

  const submitReport = async (report_reason) => {
    if (report_reason) {
      let items = {
        _id: match.params.id,
        report_reason,
        language: "ENGLISH"
      }
      try {
        let apiRes = await henceforthApi.Collection.report(items)
        toast.success(apiRes.message)
        setReportData(null)
      } catch (error) {
        toast.error(JSON.stringify(error?.response?.body?.error_description))
        return { error }
      }
    }
  }
  const initialiseItemsData = async (page) => {
    try {
      query.append('nft_type', staticState.explore)
      let q = query.toString()
      let apiRes = await henceforthApi.Collection.getItems(match.params.id, page, 10, q)
      let data = apiRes.data
      setNfts(data)
    } catch (error) {
      console.log("Error", error);
      // debugger
    }
  }

  const initialiseActivityData = async (page) => {
    try {
      let q = query.toString()
      let apiRes = await henceforthApi.Collection.getActivities(match.params.id, page, 10, q)
      let data = apiRes.data
      setActivitiesData(data)
    } catch (error) {
      console.log("Error", error);
      debugger

    }
  }
  const initialiseStatsData = async (page) => {
    try {
      let q = query.toString()
      // let apiRes = await henceforthApi.Collection.getItems(match.params.id,page, 10, q)
      // let data = apiRes.data
    } catch (error) {
      console.log("Error", error);
      debugger

    }
  }

  const enableDisablePrice = (b) => {
    if (b) {
      query.delete("min_price")
      query.delete("max_price")
      setMinPrice(0)
      setMaxPrice(0)
    } else {
      query.set("min_price", minPrice)
      query.set("max_price", maxPrice)
    }
    navigate({ search: query.toString() })
  }

  const changeSortBy = (value) => {
    if (value) {
      query.set("sort_by", value)
    } else {
      query.delete("sort_by")
    }
    navigate({ search: query.toString() })
  }
  const changeType = (value) => {
    if (query.get("type") !== value) {
      query.set("type", value)
    } else {
      query.delete("type")
    }
    navigate({ search: query.toString() })
  }

  const changeCategory = (value) => {
    if (value) {
      query.set("category_id", value)
    } else {
      query.delete("category_id")
    }
    navigate({ search: query.toString() })
  }
  const changeErc = (value) => {
    if (value) {
      query.set("erc", value)
    } else {
      query.delete("erc")
    }
    navigate({ search: query.toString() })
  }
  const changeNftSocialType = (value) => {
    if (value) {
      query.set("social_type", value)
    } else {
      query.delete("social_type")
    }
    navigate({ search: query.toString() })
  }

  useEffect(() => {
    initialiseData()
  }, [match.params.id])

  useEffect(() => {
    if (match.params.tab === 'items') {
      initialiseItemsData(0)
    } else if (match.params.tab === 'activity') {
      initialiseActivityData(0)
    } else if (match.params.tab === 'stats') {
      initialiseStatsData(0)
    }
  }, [match.params.tab, query.get("category_id"), query.get("min_price"), query.get("sort_by"), query.get("erc"), query.get("type"), query.get("social_type")])

  const shareUrl = `${window.location.origin}/collection/${match.params.id}/items`

  return <section className="activity-wrapper mt-lg-5 mt-4">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="position-relative">
            <img src={state.cover_img ? henceforthApi.FILES.imageOriginal(state.cover_img) : CoverImg} className="cover-img" />
            {authState._id && authState._id === state.created_by?._id &&
              <div className="position-absolute bottom-0 end-0 me-3 mb-3">
                <label htmlFor="filBanner">
                  <span className="btn pe-0 btn-icon">
                    <i className="fas fa-camera  border border-2  rounded-circle p-2 bg-white"></i>
                  </span>
                  <input className="d-none" type="file" id="filBanner" onChange={(e) => setCollectionBanner(e.target.files[0])} />
                </label>
              </div>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-4 position-relative mb-3">
          <div className="collection-profile p-4">
            <div className={`${state.default ? 'collection-image' : 'profile-image'} position-relative m-auto`}>
              <img src={henceforthApi.FILES.imageOriginal(state.img_url)} />
              {authState._id && authState._id === state.created_by?._id &&
                <div className="position-absolute bottom-0 end-0 ">
                  <label htmlFor="filIcon">
                    <span className="btn pe-0 btn-icon">
                      <i className="fas fa-camera  border border-2  rounded-circle p-2 bg-white"></i>
                    </span>
                    <input className="d-none" type="file" id="filIcon" onChange={(e) => setCollectionIcon(e.target.files[0])} />
                  </label>
                </div>}
            </div>
            <div className="text-center">
              <h4 className="fw-bold fw-bold mt-4 mb-1 profile-name text-break line-clamp-1">{state.name}</h4>
              <h6 className="text-gray profile-id mb-0" onClick={() => { navigator.clipboard.writeText(state.address); toast.success("Copied to clipboard") }}>{state.address}</h6>
              <div className="d-flex align-items-center justify-content-center">
                <div className="left-icon profile-share-icon  bg-white d-flex justify-content-center  my-3">
                  {/* {authState._id && authState._id === state.created_by._id &&
                  <Link to={`/profile/edit`} className="btn border border-top border-bottom share-icon px-lg-3 px-2 shadow-none">
                    <i className="fa-solid fa-pen text-muted m-0 fs-6"></i>
                  </Link>} */}
                  <button className="btn border border-top border-bottom share-icon px-3 shadow-none" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-share-alt text-muted m-0 fs-6"></i>
                  </button>
                  <ul className="dropdown-menu p-2 share-dropdown dropdown-menu-end share" aria-labelledby="dropdownMenu2">
                    <p className="fw-semi-bold text-center">Share Link</p>
                    <div>
                      <button className="btn-icon text-center mx-2" onClick={() => window.open(`https://www.twitter.com/share?url=${shareUrl}`)}>
                        <img src={Twitter} className="d-block s-icon" />
                        <span className="fs-11 text-gray">Twitter</span>
                      </button>
                      <button className="btn-icon text-center mx-2" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`)}>
                        <img src={Facebook} className="d-block s-icon" />
                        <span className="fs-11  text-gray">Facebook</span>
                      </button>
                      <button className="btn-icon text-center mx-2">
                        <a href={`mailto:info@example.com?subject=Everlens NFTs&body=${shareUrl}`}>
                          <img src={Email} className="d-block s-icon" />
                          <span className="fs-11  text-gray">Email</span>
                        </a>
                      </button>
                      <button className="btn-icon text-center mx-2" onClick={() => { navigator.clipboard.writeText(shareUrl); toast.success("Copied to clipboard") }}>
                        <img src={Copy} className="d-block s-icon" />
                        <span className="fs-11  text-gray">Copy</span>
                      </button>
                    </div>
                  </ul>

                </div>
                <div>
                  <button className="btn border border-top border-bottom share-icon dot-icon px-3 shadow-none br-rounded" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa-solid fa-ellipsis text-muted fs-6"></i>
                  </button>
                  <ul className="dropdown-menu share-dropdown dropdown-menu-end">
                    <li><button className="dropdown-item fw-semi-bold text-center fs-14" type="button" onClick={setReportData}>Report</button></li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6 pe-1">
                <div className="border item-box">
                  <p className="mb-0 text-gray fs-12">Items</p>
                  <p className="mb-0 text-dark fw-bold fs-18">{state.items_count}</p>
                </div>
              </div>
              <div className="col-md-6 ps-1">
                <div className="border item-box">
                  <p className="mb-0 text-gray fs-12">Total Volume</p>
                  <p className="mb-0 text-dark text-dark fw-bold fs-18">{subscribeFormatter(Number(state.total_volume).toFixed(3))}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 nav-tabss">
          <div className="on-salle pb-5 mb-lg-5 mt-4 pt-lg-2">

            <ul className="nav nav-pills mb-3 border-bottom" id="pills-tab" role="tablist">
              <li className={`nav-item me-4 `} role="presentation">
                <button className={`nav-link  text-secondary fs-6 fw-semi-bold bg-transparent pt-0 ps-0 me-4 pe-0 ${match.params.tab == 'items' ? 'active' : ''}`}
                  id="pills-item-tab" data-bs-toggle="pill" data-bs-target="#pills-item" type="button"
                  role="tab" aria-controls="pills-item" aria-selected="true" onClick={() => navigate(`/collection/${match.params.id}/items`, { replace: true })}>Items</button>

              </li>
              <li className={`nav-item me-4 ${match.params.tab == 'activity' ? 'active' : ''}`} role="presentation">
                <button className={`nav-link  text-secondary fs-6 fw-semi-bold bg-transparent pt-0 ps-0 me-4 pe-0 ${match.params.tab == 'activity' ? 'active' : ''}`}
                  id="pills-activity-tab" data-bs-toggle="pill" data-bs-target="#pills-activity"
                  type="button" role="tab" aria-controls="pills-activity" aria-selected="false" onClick={() => navigate(`/collection/${match.params.id}/activity`, { replace: true })}>Activity</button>
              </li>
              {/* <li className={`nav-item me-4 ${match.params.tab == 'stats' ? 'active' : ''}`} role="presentation">
                <button className={`nav-link  text-secondary fs-6 fw-semi-bold bg-transparent pt-0 ps-0 me-4 pe-0 ${match.params.tab == 'stats' ? 'active' : ''}`}
                  id="pills-stats-tab" data-bs-toggle="pill" data-bs-target="#pills-stats"
                  type="button" role="tab" aria-controls="pills-stats" aria-selected="false" onClick={() => navigate(`/collection/${match.params.id}/stats`, { replace: true })}>Stats</button>
              </li> */}
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div className={`tab-pane fade  ${match.params.tab == 'items' ? 'show active' : ''}`} id="pills-item" role="tabpanel"
                aria-labelledby="pills-item-tab">
                <div className="container p-0">
                  <div className="d-flex justify-content-between mb-4 ">
                    <div className="d-flex flex-wrap">
                      <div className="dropdown my-1">
                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2`} type="button" id="dropdownMenuCategory" data-bs-toggle="dropdown" aria-expanded="false">Category
                        </button>
                        <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuCategory">
                          <li onClick={() => changeCategory("")} className=" cursor-pointer">
                            <a className="dropdown-item fw-medium"><img src={All} className="icon me-2" />All</a>
                          </li>
                          {categories.map(res => <li key={res._id} onClick={() => changeCategory(res._id)} className=" cursor-pointer">
                            <span className="dropdown-item fw-medium">
                              <img src={res.image ? String(res.image).includes('https://') ? res.image : `${henceforthApi.API_FILE_ROOT_MEDIUM}${res.image}` : Placeholder} className="icon me-2" />
                              {res.name} {query.get("category_id") == res._id && <i className="fa-solid fa-check text-theme ms-2"></i>}
                            </span>
                          </li>
                          )}
                        </ul>
                      </div>
                      {/* <div className="dropdown my-1">
                        <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Collection
                        </button>
                        <ul className="dropdown-menu common-dropdown p-0 filters" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <div className="position-relative py-3 px-4 border-bottom">
                              <input className="form-control w-lg-50 w-100 me-1 shadow-none search-input fs-12 s-space" type="search" placeholder="Search in collection" aria-label="Search" />
                              <i className="fa-solid fa-magnifying-glass text-theme position-absolute top-50 translate-middle-y left-0 ms-3"></i>
                            </div>
                          </li>

                          <li><a className="fw-medium py-1 px-4 mt-2 d-flex align-items-center fs-12" href="#"><span className="col-circle-1 me-3"></span>Collection One</a></li>
                          <li><a className="fw-medium py-1 px-4 mb-2 d-flex align-items-center fs-12" href="#"><span className="col-circle-2 me-3"></span>Collection Two</a></li>
                          <li className="d-flex px-4 py-3 border-top ">
                            <button className="btn btn-outline-primary me-1 fw-medium fs-12 px-4" >CLEAR</button>
                            <button className="btn btn-primary fs-12 fs-12 px-4">APPLY</button>
                          </li>
                        </ul>
                      </div> */}

                      {staticState.explore == EXPLORE_LANDING_MARKET_PLACE &&
                        <div className="dropdown my-1">
                          <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuType" data-bs-toggle="dropdown" aria-expanded="false">
                            {query.has("erc") ? query.get("erc") == EXPLORE_NFT_POST_TYPE_SINGLE ? 'Single' : query.get("erc") == EXPLORE_NFT_POST_TYPE_MULTIPLE ? 'Multiple' : 'Type' : 'Type'}
                          </button>
                          <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuType">
                            <li onClick={() => changeErc("")}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={All} className="icon me-2" />All</a></li>
                            <li onClick={() => changeErc(EXPLORE_NFT_POST_TYPE_SINGLE)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={Single} className="icon me-2" />Single {721 == query.get("erc") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                            <li onClick={() => changeErc(EXPLORE_NFT_POST_TYPE_MULTIPLE)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={Multiple} className="icon me-2" />Multiple {1155 == query.get("erc") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                          </ul>
                        </div>}
                      {staticState.explore == EXPLORE_LANDING_SOCIAL &&
                        <div className="dropdown my-1">
                          <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuType" data-bs-toggle="dropdown" aria-expanded="false">
                            {query.has("social_type") ? query.get("social_type") == EXPLORE_NFT_TYPE_INSTAGRAM ? 'Instagram' : query.get("social_type") == EXPLORE_NFT_TYPE_TIKTOK ? 'Tiktok' : 'Type' : 'Type'}
                          </button>
                          <ul className="dropdown-menu common-dropdown filters" aria-labelledby="dropdownMenuType">
                            <li onClick={() => changeNftSocialType("")}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={All} className="icon me-2" />All</a></li>
                            <li onClick={() => changeNftSocialType(EXPLORE_NFT_TYPE_INSTAGRAM)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={InstagramIcon} className="icon me-2" />Instagram {EXPLORE_NFT_TYPE_INSTAGRAM == query.get("social_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                            <li onClick={() => changeNftSocialType(EXPLORE_NFT_TYPE_TIKTOK)}><a className="dropdown-item fw-medium fs-12 mb-1" href="#"><img src={TiktokIcon} className="icon me-2" />Tiktok {EXPLORE_NFT_TYPE_TIKTOK == query.get("social_type") ? <i className="fa-solid fa-check text-theme"></i> : ''}</a></li>
                          </ul>
                        </div>}

                      <div className="dropdown my-1">
                        <button className="btn btn-outline-primary px-4 text-capitalize fw-medium me-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          {query.has("min_price") ? `${query.get("min_price")} - ${query.get("max_price")} ELEN ` : 'Price Range'}
                        </button>
                        <ul className="dropdown-menu common-dropdown p-0 filters" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <span className="fw-medium py-1 px-4 my-2 d-flex align-items-center">
                              <input type="number" className="form-control me-2 border-pink" min={0} value={minPrice} placeholder="From" onChange={(e) => setMinPrice(e.target.value)} />
                              <input type="number" className="form-control border-pink" min={0} value={maxPrice} placeholder="To" onChange={(e) => setMaxPrice(e.target.value)} />
                            </span>
                          </li>
                          <li className="d-flex px-4 py-3 border-top ">
                            <button type="button" className="btn btn-outline-primary me-1 fw-medium fs-12 px-4" onClick={() => enableDisablePrice(true)}>CLEAR</button>
                            <button type="button" className="btn btn-primary fs-12 px-4" onClick={() => enableDisablePrice(false)}>APPLY</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button className="btn btn-outline-primary px-4 text-capitalize fw-medium recently-added position-relative text-nowrap" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {query.get("sort_by") == "RECENTLY_ADDED" ? 'Recently Added' : query.get("sort_by") == "NEW" ? 'New' : query.get("sort_by") == "SOLD" ? 'Sold' : query.get("sort_by") == "PRICE_HIGH_TO_LOW" ? 'Price -- High to Low' : query.get("sort_by") == "PRICE_LOW_TO_HIGH" ? 'Price -- Low to High' : query.get("sort_by") == "AUCTION_ENDING_SOON" ? 'Auction Ending Soon' : query.get("sort_by") == "FIXED_PRICE" ? 'Fixed Price' : query.get("sort_by") == "FOLLOWERS" ? 'Followers' : 'Explore'}
                        <span className="sort position-absolute start-50 translate-middle-x bg-white text-theme">Sort</span>
                      </button>
                      <ul className="dropdown-menu common-dropdown dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => changeSortBy("RECENTLY_ADDED")}><a className="dropdown-item fw-medium cursor-pointer" >Recently Added</a></li>
                        <li onClick={() => changeSortBy("PRICE_HIGH_TO_LOW")}><a className="dropdown-item fw-medium cursor-pointer" >Price -- High to Low</a></li>
                        <li onClick={() => changeSortBy("PRICE_LOW_TO_HIGH")}><a className="dropdown-item fw-medium cursor-pointer" >Price -- Low to High</a></li>
                        <li onClick={() => changeSortBy("AUCTION_ENDING_SOON")}><a className="dropdown-item fw-medium cursor-pointer" >Auction Ending Soon</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    {nfts.items.map((res, index) => {
                      return <div key={res._id} className="col-xl-4 col-md-6 mb-3"> <NftCard currentUser={authState._id} elenToUsd={elenToUsd} {...res} initialiseFev={initialiseFev} index={index} /></div>
                    })}
                  </div>
                </div>
              </div>

              <div className={`tab-pane fade  ${match.params.tab == 'activity' ? 'show active' : ''}`} id="pills-activity" role="tabpanel"
                aria-labelledby="pills-activity-tab">
                <div className="container p-0">
                  <div className="d-flex justify-content-between mb-4 ">
                    <div className="d-flex flex-wrap">
                      <div className="dropdown my-1">
                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("type") === "LISTINGS" ? 'select' : ''}`} type="button" onClick={() => changeType("LISTINGS")}>
                          <img src={Listing} alt="" className="me-2" />Listing
                        </button>

                      </div>
                      <div className="dropdown my-1">
                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("type") === "PURCHASE" ? 'select' : ''}`} type="button" onClick={() => changeType("PURCHASE")}>
                          <img src={Purchase} alt="" className="me-2" />Purchase
                        </button>

                      </div>
                      <div className="dropdown my-1">
                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("type") === "SALES" ? 'select' : ''}`} type="button" onClick={() => changeType("SALES")}>
                          <img src={Sale} alt="" className="me-2" />Sale
                        </button>

                      </div>
                      <div className="dropdown my-1">
                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("type") === "FAVOURITES" ? 'select' : ''}`} type="button" onClick={() => changeType("FAVOURITES")}>
                          <img src={Transfer} alt="" className="me-2" />Transfer
                        </button>
                      </div>
                      <div className="dropdown my-1">
                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("type") === "BIDS" ? 'select' : ''}`} type="button" onClick={() => changeType("BIDS")}>
                          <img src={Burn} alt="" className="me-2" />Burn
                        </button>
                      </div>
                      <div className="dropdown my-1">
                        <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("type") === "COMMENTS" ? 'select' : ''}`} type="button" onClick={() => changeType("COMMENTS")}>
                          <img src={Like} alt="" className="me-2" />Comment
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    {Array.isArray(activitiesData.activities) && activitiesData.activities.map(res =>
                      <div className="profile-name border p-3 rounded-2 mb-3">
                        <div className=" d-flex justify-content-start align-items-center">
                          <div className="me-3">
                            <img src={henceforthApi.FILES.imageMedium(res?.nft_id?.file_url)} alt="profile" className="square-profile rounded-3 object-fit-cover" />
                          </div>
                          <div>
                            <h6 className="mb-0 text-theme fw-semi-bold">{res?.nft_id?.name}</h6>
                            <p className="mb-0 text-muted"><span className="me-4">{res?.message}</span>
                              {/* <span>
                                <strong className="text-dark  d-inline-flex align-items-center">
                                  <span
                                    className="d-inline-block bg-dark rounded-circle mx-1 big-circle "></span>
                                  0x7dfbee423...254f</strong> <span className="mx-1">to</span>
                                <strong className="text-dark d-inline-flex align-items-center"><span
                                  className="d-inline-block bg-dark rounded-circle mx-1 big-circle"></span>
                                  0x7dfbee423...254f</strong>
                              </span> */}
                            </p>
                            <p className="text-muted mb-0 mt-1"><small>{moment(Number(res.created_at)).format("MMM DD, YYYY")} {moment(Number(res.created_at)).format("hh:mma")}</small></p>
                          </div>
                        </div>
                      </div>)}
                  </div>
                </div>
              </div>

              <div className={`tab-pane fade  ${match.params.tab == 'stats' ? 'show active' : ''}`} id="pills-stats" role="tabpanel"
                aria-labelledby="pills-stats-tab">
                <div className="container p-0">
                  <div className="d-flex flex-wrap">
                    <div className="dropdown my-1">
                      <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2  ${query.get("period") === "LISTINGS" ? 'select' : ''}`} type="button" id="dropdownMenuCategory" data-bs-toggle="dropdown" aria-expanded="false">
                        7 Days
                      </button>
                    </div>
                    <div className="dropdown my-1">
                      <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("period") === "LISTINGS" ? 'select' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        30 Days
                      </button>

                    </div>
                    <div className="dropdown my-1">
                      <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("period") === "LISTINGS" ? 'select' : ''}`} type="button" id="dropdownMenuType" data-bs-toggle="dropdown" aria-expanded="false">
                        90 Days
                      </button>

                    </div>
                    <div className="dropdown my-1">
                      <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("period") === "LISTINGS" ? 'select' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        1 Year
                      </button>
                    </div>
                    <div className="dropdown my-1">
                      <button className={`btn btn-outline-primary px-4 text-capitalize fw-medium me-2 ${query.get("period") === "LISTINGS" ? 'select' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        All Time
                      </button>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="stats-content mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <h4>Sales History</h4>
                            <div className="ms-3">
                              <button className="btn btn-primary px-3 text-capitalize">Average</button>
                              <button className="btn btn-outline-primary ms-2 px-3 text-capitalize">Median</button>
                            </div>
                          </div>
                          <button className="btn btn-primary px-4">BUY NOW</button>
                        </div>
                        <p className="fs-12"><span className="text-gray me-1">Current avg. price:</span><span className="text-dark fw-semi-bold">$757</span> </p>
                        <div className="">
                          {/* <Line /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
    <Modals.Report data={reportData} setData={setReportData} onSubmit={submitReport} />
  </section >
}
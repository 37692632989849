import Lottie from 'react-lottie';
import animationData from '../../lotties/congratulations.json'

export default (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    return <div className={`modal fade  ${props.show ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={props.show ? 'dialog' : ''} style={{ display: props.show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="container">
                <div className="col-md-8 m-auto">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-0 ">
                            <button type="button" className="btn border-0 shadow-none fs-6 position-absolute top-0 end-0" onClick={() => { props.setBuyQuantity(null) }} style={{ zIndex: 10 }}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>

                        <div className="modal-body border-0 position-relative py-5 px-4">

                            {props.children}
                            <div className='lottie-wrapper'>
                                <Lottie
                                    height={275}
                                    width={300}
                                    speed={0.5}
                                    hideOnTransparent={true}
                                    options={defaultOptions}
                                    isClickToPauseDisabled={true}
                                    style={{

                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
import { useState } from "react"
import { Spinner } from "../BootstrapCompo"

export default ({ data, setData, onSubmit }) => {
    const [text, setText] = useState("")
    const [loading, setLoading] = useState(false)

    const submitReport = async () => {
        setLoading(true)
        let res = await onSubmit(text.trim())
        setLoading(false)
        if (res) {
            setText("")
        }
    }

    return <div className={`modal fade common-modal report-modal ${data ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={data ? 'dialog' : ''} style={{ display: data ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <button type="button" className="btn-close shadow-none" onClick={() => setData(null)}></button>
                <div className="modal-body text-center">
                    <h5 className="fw-bold mb-3"><span className="text-gradient">Why are you reporting?</span></h5>
                    <h6 className="text-gray mb-4 fw-normal">Tell us how this user violates the rules of the site</h6>
                    <textarea type="text" className="form-control border-pink border caption fw-normal" style={{ minHeight: '75px' }}
                        placeholder="Write message here" name="report" value={text} onChange={(e) => setText(e.target.value)}>
                    </textarea>
                    <button className="btn btn-primary py-2 mt-4 w-100" onClick={submitReport} disabled={loading}>{loading ? <Spinner /> : 'SEND MESSAGE'}</button>
                </div>

            </div>
        </div>
    </div>
}
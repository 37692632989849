import React, { useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "../../components/BootstrapCompo";
import henceforthApi from "../../utils/henceforthApi";
import henceforthValidations from "../../utils/henceforthValidations";

export default () => {

  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const submitContactUs = async () => {
    if (!email) {
      return toast.warn("Please enter email.")
    }
    if (!henceforthValidations.email(email)) {
      return toast.warn("Enter valid email address.")
    }
    if (!subject.trim()) {
      return toast.warn("Please enter subject.")
    }
    if (!message.trim()) {
      return toast.warn("Please enter message.")
    }

    setLoading(true)
    const items = {
      email: email,
      subject: subject,
      message: message,
      language: "ENGLISH"
    }
    try {
      let apiRes = await henceforthApi.Common.contentUs(items)
      toast.success(apiRes?.message)
      setEmail("")
      setSubject("")
      setMessage("")

    } catch (error) {
      toast.error(JSON.stringify(error?.response?.body?.error_response))
    } finally {
      setLoading(false)
    }
  }

  return <form onSubmit={(e) => { e.preventDefault(); submitContactUs() }} >
    <div className="inner-pages-wrapper">
      <div className="container">
        <div className="create-contact-wrapper">
          <div className="title-col no-cont">
            <h2>Contact Us</h2>
          </div>
          <div className="create-inner">
            <div className="create-fieldstyle">
              <label>
                Email<sup>*</sup>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                formControlName="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </div>
            <div className="create-fieldstyle">
              <label>
                Subject<sup>*</sup>
              </label>
              <div className="eth-col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Subject"
                  formControlName="subject"
                  name="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="create-fieldstyle">
              <label>
                Message<sup>*</sup>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter Message"
                formControlName="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={loading}
              />
            </div>
            <div className="create-fieldstyle">
              <button type="submit" className="btn btn-primary w-100">{loading ? <Spinner /> : 'SEND'}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
}
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, AUTH_AGE_VERIFY, SIGNUP_SUCCESS, INITIALISE_PROFILE, SOCIAL_TYPE_PROVIDER, CHAT_STATUS, AUTH_FEV, DELETE_INSTAGRAM, DELETE_TIKTOK } from "../actionTypes";

const auth = (state, { type, payload }) => {
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLogin: true,
                isSignUp: false,
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                access_token: "",
                email: null,
                email_verified: null,
                user: null,
                user_name: null,
                account_status: null,
                account_type: null,
                device_type: null,
                token_gen_at: null,
                admin_verified: null,
                social_token: null,
                social_type: null,
                fcm_token: null,
                provider: "",
                type: null,
                _id: null,
                instagram: null,
                walletType: {
                    type: ""
                },
                isLogin: false,
                isSignUp: false,
                favourite: [],
            }
        case AUTH_AGE_VERIFY:
            return {
                ...state,
                ...payload,
            }
        // favourite
        case AUTH_FEV: {
            let index = payload.index
            let oldFavourite = state.favourite
            oldFavourite.splice(index, 1)
            return {
                ...state,
                favourite: oldFavourite
            }
        }
        case INITIALISE_PROFILE:
            return {
                ...state,
                ...payload,
            }
        case DELETE_INSTAGRAM:
            return {
                ...state,
                instagram: null,
            }
        case DELETE_TIKTOK:
            return {
                ...state,
                tiktok: null,
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                ...payload,
                isLogin: false,
                isSignUp: true,
            }
        case SOCIAL_TYPE_PROVIDER:
            return {
                ...state,
                ...payload,
            }
        case CHAT_STATUS:
            return {
                ...state,
                ...payload,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default auth;
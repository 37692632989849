
import Twitter from "../assets/images/icons/twitter.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import Email from "../assets/images/icons/email.svg";
import Copy from "../assets/images/icons/copy.svg";
import { toast } from "react-toastify";

export default () => {
    return <ul className="dropdown-menu p-2 share-dropdown dropdown-menu-end share" aria-labelledby="dropdownShare">
        <p className="fw-semi-bold text-center">Share Link</p>
        <div>
            <button className="btn-icon text-center mx-2" onClick={() => window.open(`https://www.twitter.com/share?url=${encodeURIComponent(window.location.href)}`)}>
                <img src={Twitter} className="d-block s-icon" />
                <span className="fs-11 text-gray">Twitter</span>
            </button>
            <button className="btn-icon text-center mx-2" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`)}>
                <img src={Facebook} className="d-block s-icon" />
                <span className="fs-11  text-gray">Facebook</span>
            </button>
            <button className="btn-icon text-center mx-2">
                <a href={`mailto:info@example.com?subject=Everlens NFTs&body=${encodeURIComponent(window.location.href)}:`}>
                    <img src={Email} className="d-block s-icon" />
                    <span className="fs-11  text-gray">Email</span>
                </a>
            </button>
            <button className="btn-icon text-center mx-2" onClick={() => { navigator.clipboard.writeText(window.location.href); toast.success("Copied to clipboard") }}>
                <img src={Copy} className="d-block s-icon" />
                <span className="fs-11  text-gray">Copy</span>
            </button>
        </div>
    </ul>
}
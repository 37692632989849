import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import henceforthApi from "../../utils/henceforthApi"
import metamaskContracts from "../../utils/metamaskContracts"
import { Spinner } from "../BootstrapCompo"

export default ({ provider }) => {

    const [collectionAddress, setCollectionAddress] = useState("")
    const [erc, setErc] = useState("721")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const importCollection = async (address) => {
        try {
            setLoading(true)
            let { owner, name, symbol, uri, accounts, eth_chainId } = await metamaskContracts.importCollection(address, provider)
            if (accounts.length) {

                let ownerRes = Array.isArray(owner) ? owner[0] : owner
                if (String(accounts[0]).toLowerCase() !== String(ownerRes).toLowerCase()) {
                    return toast.warn("You are not owner of this collection")
                }
                let nameRes = Array.isArray(name) ? name[0] : name
                let symbolRes = Array.isArray(symbol) ? symbol[0] : symbol
                let source = Array.isArray(uri) ? uri[0] : uri

                if (source.includes("{address}")) {
                    source = source.replace("{address}", collectionAddress)
                }
                let collectionRes = await (await fetch(source)).json()
                console.log("apiRes", collectionRes);
                let imageUrl = String(collectionRes.image).includes("http") ? collectionRes.image : collectionRes.image.split("/")
                if (Array.isArray(imageUrl)) {
                    imageUrl = imageUrl[imageUrl.length - 1];
                    imageUrl = `ipfs://${imageUrl}`
                }
                let items = {
                    address: collectionAddress,
                    img_url: imageUrl,
                    name: nameRes,
                    symbol: symbolRes,
                    erc: Number(erc),
                    language: "ENGLISH"
                }

                let closeImportCollectionModal = document.getElementById("closeImportCollectionModal")
                let apiRes = await henceforthApi.Collection.create(items)
                let data = apiRes.data
                debugger
                if (closeImportCollectionModal) {
                    closeImportCollectionModal.click()
                }
                setCollectionAddress("")
                setLoading(false)
                navigate(`/collection/${data._id}/items`)
            }
        } catch (error) {
            setLoading(false)
            console.log(error.response);
            if (error.response) {
                if (error.response.body) {
                    toast.error(error.response.body.message)
                }
            } else {
                toast.warn("Something wrong in collection.")
            }
        }
    }


    return <div className="modal fade common-modal import-modal" id="importModal" tabIndex="-1" aria-labelledby="importModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                    <form onSubmit={(e) => { e.preventDefault(); importCollection(collectionAddress) }}>
                        <button id="closeImportCollectionModal" type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
                        <h3 className="text-dark text-center title mb-2"><span className="text-gradient">Import Your Collection</span></h3>
                        <p className="text-gray mb-3 subtext">What is the address of your ERC721 or ERC1155 contract on the Everlens Network?</p>
                        <div className="form-group">
                            <label className="fw-medium mb-1">Collection Type</label>
                            <select value={erc} className="form-select" onChange={(e) => setErc(e.target.value)} required>
                                <option value="721">ERC 721</option>
                                <option value='1155'>ERC 1155</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="fw-medium mb-1">Collection Address</label>
                            <input type="text" placeholder="Collection Address" className="form-control" value={collectionAddress} onChange={(e) => setCollectionAddress(e.target.value)} required />
                        </div>
                        <div className="pt-3">
                            <button className="btn btn-primary w-100 " type="submit" disabled={loading}>{loading ? <Spinner /> : 'SUBMIT'}</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
}
import { Fragment, useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import authAction from "../../../context/actions/auth/authAction"
import { GlobalContext } from "../../../context/Provider"
import henceforthApi from "../../../utils/henceforthApi"
import henceforthValidations from "../../../utils/henceforthValidations"
import { Spinner } from "../../BootstrapCompo"
import Modals from "../../Modals"

export default () => {
    const navigate = useNavigate()
    const { authState, authDispatch, loading, setLoading } = useContext(GlobalContext)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [cnfPassword, setCnfPassword] = useState("")

    const [passwordToggle1, setPasswordToggle1] = useState(false)
    const [passwordToggle2, setPasswordToggle2] = useState(false)
    const onSignUp = async () => {
        if (!name) {
            return toast.warn("Please enter your name")
        } else if (!email) {
            return toast.warn("Please enter your email id")
        } else if (!henceforthValidations.email(email)) {
            return toast.warn("Please enter your valid email id")
        } else if (!username) {
            return toast.warn("Please enter username")
        } else if (!password) {
            return toast.warn("Please enter password")
        } else if (!henceforthValidations.strongPassword(password)) {
            return toast.warn("Please enter stronger password. Try a mix of letters, numbers and symbols.")
        } else if (!cnfPassword) {
            return toast.warn("Please enter confirm password")
        } else if (password !== cnfPassword) {
            return toast.warn("Password and confirm password don't match.")
        }

        let items = {
            name: name,
            user_name: username,
            email: email,
            password: password,
            language: "ENGLISH"
        }
        try {
            setLoading(true)
            let apiRes = await henceforthApi.Auth.signUp(items)
            let data = apiRes.data
            authAction.signup(data)(authDispatch)
            var query = new URLSearchParams();
            query.set('verify', 'email')
            query.set('action', 'email')
            navigate({ search: query.toString() }, { replace: true })
            setLoading(false)
        } catch (error) {
            console.log("error.response", error.response);
            setLoading(false)
            toast.error(error?.response?.body?.error_description)
        }

    }
    return <Fragment>
        <form onSubmit={(e) => { e.preventDefault(); onSignUp() }}>
            <p className="text-gray mb-4 fw-medium">Step 1 of 3</p>

            <div className="form-group">
                <label className="fw-medium mb-1">Name</label>
                <input type="text" className="form-control" placeholder="Name" name="name" value={name} onChange={(e) => henceforthValidations.nameValidation(e.target.value) ? setName(e.target.value) : console.log(e.target.value)} required />
            </div>
            <div className="form-group">
                <label className="fw-medium mb-1">Email</label>
                <input type="email" className="form-control" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="form-group">
                <label className="fw-medium mb-1">Username</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">@</span>
                    </div>
                    <input type="text" className="form-control" placeholder="Username" name="username" aria-label="Username" aria-describedby="basic-addon1" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </div>

            </div>
            <div className="form-group">
                <label className="fw-medium mb-1">Password</label>
                <div className="position-relative">
                    <input type={passwordToggle1 ? 'text' : 'password'} className="form-control pe-5" placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <i className={`${passwordToggle1 ? 'fa-eye-slash' : 'fa-eye'} fa-solid position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle1(!passwordToggle1)}></i>
                </div>

            </div>
            <div className="form-group">
                <label className="fw-medium mb-1">Confirm Password</label>
                <div className="position-relative">
                    <input type={passwordToggle2 ? 'text' : 'password'} className="form-control" placeholder="Confirm Password" name="confirm-password" value={cnfPassword} onChange={(e) => setCnfPassword(e.target.value)} required />
                    <i className={`${passwordToggle2 ? 'fa-eye-slash' : 'fa-eye'} fa-solid position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle2(!passwordToggle2)}></i>
                </div>
            </div>
            <p className="py-4">By creating an account you agree with our <Link to="/terms-condition" className="text-theme">Terms of Service</Link> and <Link to="/privacy-policy" className="text-theme">Privacy Policy</Link>.</p>
            <button type="submit" className="btn btn-primary w-100 text-uppercase py-2" disabled={loading}>{loading ? <Spinner /> : 'SIGN UP'}</button>
            <div className="text-center">
                <p className="text-gray mt-4">Already have an account? <Link to="/signin" className="text-theme">Sign In</Link></p>
            </div>
        </form>
        <Modals.VerificationOfEmail {...authState} />
    </Fragment>
}
import { Fragment, useState } from "react"
import { Calendar } from "react-calendar"
import { toast } from "react-toastify"
import { NFT_STEP_1, NFT_STEP_2, NFT_STEP_3, NFT_STEP_4, } from "../context/actionTypes"
import henceforthApi from "../utils/henceforthApi"
import { InstagramPlayer, Spinner, TiktokPlayer } from "./BootstrapCompo"
import CreateCollection from './collection/CreateCollection'
import ImportCollection from './collection/ImportCollection'
import PutOnSaleModal from './modals/PutOnSaleModal'
import CreateAnAuction from './modals/CreateAnAuction'
import PlaceABid from './modals/PlaceABid'
import PlaceOffer from './modals/PlaceOffer'
import BuyNft from './modals/BuyNft'
import Followers from './modals/Followers'
import Following from './modals/Following'
import BurnNft from './modals/BurnNft'
import Report from './modals/Report'
import TikTokRequest from './modals/TikTokRequest'
import StartDatePicker from './modals/StartDatePicker'
import EndDatePicker from './modals/EndDatePicker'
import Confirm from './modals/Confirm'
import VerificationOfEmail from './modals/VerificationOfEmail'
import OpenWallet from './modals/OpenWallet'
import Warning from './modals/Warning'
import Congratulation from './modals/Congratulation'
import henceforthValidations from "../utils/henceforthValidations"

const ReportModal = ({ text, setText, onSubmit }) => {
  const [loading, setLoading] = useState(false)

  const submitReport = async () => {
    setLoading(true)
    await onSubmit()
    const submitReport = document.getElementById("closeReportNftModal")
    if (submitReport) {
      submitReport.click()
    }
    setLoading(false)

  }
  return (
    <div className="modal fade common-modal report-modal" id="reportModal" tabIndex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <button type="button" id="closeReportNftModal" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body text-center">
            <h5 className="fw-bold mb-3"><span className="text-gradient">Why are you reporting?</span></h5>
            <h6 className="text-gray mb-4 fw-normal">Tell us how this user violates the rules of the site</h6>
            <textarea type="text" className="form-control border-pink border caption fw-normal" style={{ minHeight: '75px' }}
              placeholder="Write message here" name="report" value={text} onChange={(e) => setText(e.target.value)}>
            </textarea>
            <button className="btn btn-primary py-2 mt-4 w-100" onClick={submitReport} disabled={loading}>{loading ? <Spinner /> : 'SEND MESSAGE'}</button>
          </div>

        </div>
      </div>
    </div>
  )
}
const NftCreateStep = ({ createNftStep }) => {
  return <>
    <button type="button" id="btnOpenMintModal" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#steps"></button>
    <div className="modals-name steps">
      <div className={`modal fade steps common-modal steps-modal ${createNftStep ? 'show' : ''}`} data-bs-backdrop="static" id="steps" tabIndex="-1" aria-labelledby="exampleModalLabel" role={createNftStep ? 'dialog' : ''} style={{ display: createNftStep ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <button id="btnCloseMintModal" type="button" className="btn d-none shadow-none fs-6 position-absolute top-0 end-0 z-index-1" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
            <div className="modal-body py-4">
              <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-3 d-flex justify-content-center" id="buystepsModalLabel">
                <span className="text-gradient">Follow Steps</span></h5>
              <div className="nft-body-modal">

                <div className="upload-steps">
                  <div className="step-img">
                    {createNftStep == NFT_STEP_1 ?
                      <Spinner /> : (createNftStep > NFT_STEP_1) ?
                        <div >
                          <i className="fa fa-check  text-primary" aria-hidden="true"></i>
                        </div> : <div >
                          <i className="fa fa-check color-inherit" aria-hidden="true"></i>
                        </div>}
                  </div>
                  <div className="step-text">
                    <h2>Upload files</h2>
                    <p>Adding your asset to IPFS</p>
                  </div>
                </div>

                <div className="upload-steps">
                  <div >
                    {createNftStep == NFT_STEP_2 ?
                      <Spinner /> : (createNftStep > NFT_STEP_2) ?
                        <div className="step-img">
                          <i className="fa fa-check  text-primary" aria-hidden="true"></i>
                        </div> : <div className="step-img">
                          <i className="fa fa-check color-inherit" aria-hidden="true"></i>
                        </div>}
                  </div>
                  <div className="step-text">
                    <h2>Mint token </h2>
                    <p>Adding your asset to the blockchain and putting it into circulation to be bought or sold.</p>
                  </div>
                </div>

                <div className="upload-steps">
                  <div className="step-img">
                    {createNftStep == NFT_STEP_3 ?
                      <Spinner /> : (createNftStep > NFT_STEP_3) ?
                        <div >
                          <i className="fa fa-check  text-primary" aria-hidden="true"></i>
                        </div> : <div >
                          <i className="fa fa-check color-inherit" aria-hidden="true"></i>
                        </div>}
                  </div>
                  <div className="step-text">
                    <h2>Approve Asset</h2>
                    <p>Approve perfoming transactions with your wallet</p>
                  </div>
                </div>

                <div className="upload-steps">
                  <div>
                    {createNftStep == NFT_STEP_4 ?
                      <Spinner /> : (createNftStep > NFT_STEP_4) ?
                        <div className="step-img">
                          <i className="fa fa-check text-primary" aria-hidden="true"></i>
                        </div> : <div className="step-img">
                          <i className="fa fa-check color-inherit" aria-hidden="true"></i>
                        </div>}
                  </div>
                  <div className="step-text">
                    <h2>List</h2>
                    <p>Listing your asset on Everlens so it can be found and purchased by users.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
const NftPurchaseStep = ({ createNftStep }) => {
  return <div className="modal fade" id="buystepsModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="buystepsModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <div className="modal-content">
              <div className="modal-header border-0 p-0">
                <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-2  pt-4 d-flex justify-content-center" id="buystepsModalLabel">
                  <span className="text-gradient">Follow Steps</span></h5>
                <button id="closeBuyStepModal" type="button" className="d-none btn border-0  shadow-none fs-6 position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
              </div>
              <div className="modal-body p-4 pt-2">
                <div>
                  <div className="d-flex align-items-start">
                    {createNftStep == NFT_STEP_1 ?
                      <Spinner /> : (createNftStep > NFT_STEP_1) ?
                        <i className="fa fa-check text-primary" aria-hidden="true"></i>
                        :
                        <i className="fa-solid fa-check"></i>
                    }
                    <div className="ms-3">
                      <h6 className="mb-0 fw-bold">Approve assets</h6>
                      <p>This transcation is conducted only once per asset type</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    {createNftStep == NFT_STEP_4 ?
                      <Spinner /> : (createNftStep > NFT_STEP_4) ?
                        <i className="fa fa-check text-primary" aria-hidden="true"></i>
                        :
                        <i className="fa fa-check color-inherit" aria-hidden="true"></i>
                    }
                    <div className="ms-3">
                      <h6 className="mb-0 fw-bold">Purchase</h6>
                      <p>Send transaction to purchase assets</p>
                    </div>
                  </div>
                </div>
                {/* <button className="btn btn-outline-primary w-100 fs-12 mb-2">Cancel</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
const SelectDate = ({ calendarRef, handleSelectDate, minDate }) => {
  return <Fragment>
    <button data-bs-toggle="modal" data-bs-target="#openDatePicker" className='d-none' id='openDatePickerModal'></button>
    <div className="modal fade common-modal" id="openDatePicker" tabIndex="-1" aria-labelledby="openDatePickerLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="modal-content">
                <div className="modal-header border-0 p-0 ">
                  <button id='closeOpenDatePicker' type="button" className="btn border-0 shadow-none fs-6 position-absolute top-0 end-0 z-index-1" data-bs-dismiss="modal" aria-label="Close" ><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="modal-body">
                  <Calendar inputRef={calendarRef} onChange={handleSelectDate} minDate={minDate} className="calendar w-100" />
                  <div className="d-flex justify-content-between align-items-center my-3">
                    {/* <p className="text-gray mb-0">Select Time</p>
                                        <p className="fw-semi-bold mb-0">12:58 PM  <i className="fa-solid fa-clock fw-medium text-theme ms-2"></i></p> */}
                  </div>
                  <button className='btn btn-primary w-100' data-bs-dismiss="modal" aria-label="Close">Apply</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
}
const InstagramFiles = ({ photos, album, videos, selectedFile, onSelectedFile, initialiseReels, reelsData, initialiseStory, storyData }) => {
  return <div
    className="modal fade"
    id="selectFileModal"
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title mb-0" id="exampleModalLabel">
            <span className="text-gradient fw-semi-bold">Select Item From</span>
          </h5>
          <button
            id="closeSelectFile"
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => onSelectedFile(null)}
          ></button>
        </div>
        <div className="modal-body nav-select">
          <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="photos-tab"
                data-bs-toggle="tab"
                data-bs-target="#photos"
                type="button"
                role="tab"
                aria-controls="photos"
                aria-selected="true"
              >
                Photos
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="album-tab"
                data-bs-toggle="tab"
                data-bs-target="#album"
                type="button"
                role="tab"
                aria-controls="album"
                aria-selected="false"
              >
                Album
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="video-tab"
                data-bs-toggle="tab"
                data-bs-target="#video"
                type="button"
                role="tab"
                aria-controls="video"
                aria-selected="false"
              >
                Videos
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="reels-tab"
                data-bs-toggle="tab"
                data-bs-target="#reels"
                type="button"
                role="tab"
                aria-controls="reels"
                aria-selected="false"
                onClick={() => initialiseReels()}
              >
                Reels
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="story-tab"
                data-bs-toggle="tab"
                data-bs-target="#story"
                type="button"
                role="tab"
                aria-controls="story"
                aria-selected="false"
              >
                Story
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="photos"
              role="tabpanel"
              aria-labelledby="photos-tab"
            >
              <div className="nft-player nft-image">
                <div className="row">
                  {photos.map((res) => {
                    return (
                      <div
                        key={res.id}
                        className="col-xl-3 col-md-4 col-sm-6"
                        onClick={() => onSelectedFile({ ...res })}>
                        <div className="ss-imge">
                          <InstagramPlayer {...res} selectedFile={selectedFile} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="album" role="tabpanel" aria-labelledby="album-tab">
              <div className="nft-player nft-image">
                <div className="row">
                  {album.map((res) => {
                    return (
                      <div
                        key={res.id}
                        className="col-xl-3 col-md-4 col-sm-6"
                        onClick={() => onSelectedFile({ ...res })}>
                        <div className="ss-imge">
                          <InstagramPlayer {...res} selectedFile={selectedFile} />
                        </div>

                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
              <div className="nft-player nft-image">
                <div className="row">
                  {videos.map((res) => {
                    return (
                      <div
                        key={res.id}
                        className="col-xl-3 col-md-4 col-sm-6"
                        onClick={() => onSelectedFile({ ...res })}>
                        <div className="ss-imge">
                          <InstagramPlayer {...res} selectedFile={selectedFile} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="reels" role="tabpanel" aria-labelledby="reels-tab">
              <div className="nft-player nft-image">
                <div className="row">
                  {reelsData.loading ? <div className="load-content d-flex align-items-center justify-content-center col-md-12 mb-3"> <Spinner /> </div> : Array.isArray(reelsData.data)
                    ? reelsData.data.map((res) => {
                      return (
                        <div
                          className="col-xl-3 col-md-4 col-sm-6"
                          onClick={() => onSelectedFile({ ...res, id: res.video_url.base_url, media_url: res.video_url.base_url, media_type: "REELS", })}>
                          <div className="ss-imge">
                            <InstagramPlayer {...res} selectedFile={selectedFile} media_url={res.video_url.base_url} media_type={`REELS`} id={res.video_url.base_url} />
                          </div>
                        </div>
                      );
                    })
                    : ""}
                  <button className="btn btn-outline-primary fw-semi-bold" onClick={() => initialiseReels()} disabled={reelsData.loading}>Load More</button>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="story" role="tabpanel" aria-labelledby="story-tab">
              <div className="nft-player nft-image">
                <div className="row">
                  {storyData.loading ? <div className="load-content d-flex align-items-center justify-content-center col-md-12  mb-3"> <Spinner /> </div> : Array.isArray(storyData.data)
                    ? storyData.data.map((res) => {
                      return (
                        <div
                          className="col-xl-3 col-md-4 col-sm-6"
                          onClick={() => onSelectedFile({ ...res, id: res.video_url.base_url, media_url: res.video_url.base_url, media_type: "STORY", })}>
                          <div className="ss-imge">
                            <InstagramPlayer {...res} selectedFile={selectedFile} media_url={res.video_url.base_url} media_type={`STORY`} id={res.video_url.base_url} />
                          </div>
                        </div>
                      );
                    })
                    : ""}
                  <button className="btn btn-outline-primary fw-semi-bold" onClick={() => initialiseStory()} disabled={storyData.loading}>Load More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-secondary fw-medium" data-bs-dismiss="modal" onClick={() => onSelectedFile(null)}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary py-2"
            data-bs-dismiss="modal"
          // disabled={!selectedFile}
          >
            Upload Now
          </button>
        </div>
      </div>
    </div>
  </div>
}
const TiktokFiles = ({ videos, selectedFile, onSelectedFile, loading, initialiseTiktokData }) => {
  return <div
    className="modal fade"
    id="selectFileModal"
    tabIndex="-1"
    aria-labelledby="selectFileModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content">
        <div className="modal-header">

          <h5 className="modal-title mb-0" id="selectFileModalLabel">
            <span className="text-gradient fw-semi-bold">Select Item From</span>
          </h5>
          <button
            id="closeSelectFile"
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => onSelectedFile(null)}
          ></button>
        </div>
        <div className="modal-body">
          <div className="nft-player nft-image">
            {loading ? <div className="load-content d-flex align-items-center justify-content-center col-md-12 mb-3"> <Spinner /> </div> :
              <div className="row">
                {videos.map((res) => {
                  return (
                    <div
                      key={res.id}
                      className="col-xl-3 col-md-4 col-sm-6"
                      onClick={() => onSelectedFile({ ...res, media_url: res.playAddr, media_type: "TIKTOK" })}>
                      <div className="ss-imge">
                        <TiktokPlayer {...res} selectedFile={selectedFile} />
                      </div>
                    </div>
                  );
                })}
              </div>}
            <button className="btn btn-outline-primary fw-semi-bold w-100" onClick={() => initialiseTiktokData()} disabled={loading}>Load More</button>

          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-secondary fw-medium" data-bs-dismiss="modal" onClick={() => onSelectedFile(null)}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary py-2"
            data-bs-dismiss="modal"
          // disabled={!selectedFile}
          >
            Upload Now
          </button>
        </div>
      </div>
    </div>
  </div >
}
const VerifyEmail = ({ initialiseProfile, resendOtp, timerCount }) => {
  const [otp, setOtp] = useState("")
  const [loading, setLoading] = useState(false)
  const verify = async () => {
    if (!otp) {
      return toast.warn("Please enter your OTP")
    }
    try {
      let items = {
        otp: Number(otp),
        language: "ENGLISH"
      }
      setLoading(true)
      let apiRes = await henceforthApi.Auth.emailVerification(items)
      initialiseProfile(apiRes.data)
      setLoading(false)
      let closeVerifyEmail = document.getElementById("closeVerifyEmail")
      if (closeVerifyEmail) {
        closeVerifyEmail.click()
      }
    } catch (error) {

      setLoading(false)
      if (error.response && error.response.body) {
        toast.error(error.response.body.error_description)
      } else {
        toast.error(JSON.stringify(error))
      }
    }
  }
  const resendOtpNow = async () => {
    await resendOtp()
  }
  return <div className="modal fade" id="VerifyEmailModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="fixedPriceModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <form className="row" onSubmit={(e) => { e.preventDefault(); verify() }}>
          <div className="col-md-8 m-auto">
            <div className="modal-content">
              <div className="modal-header border-0 p-0">

                <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pt-4 d-flex justify-content-center " id="fixedPriceModalLabel">
                  <span className="text-gradient">Verify Email</span></h5>
                <button id="closeVerifyEmail" type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0 border-0" style={{ cursor: "pointer" }} data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
              </div>
              <div className="modal-body pt-1 pb-0 p-4">
                <div className="position-relative my-3">
                  <div className="input-group ">
                    <input type="text" className="form-control border-pink pe-5 mb-1" placeholder="Enter OTP " value={otp} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setOtp(e.target.value) : console.log("wrong keywork")} maxLength="4" />
                  </div>
                  <p className="fs-10 text-dark mb-0 text-center mt-3">Didn't receive any code?</p>
                  <p className="mb-0 text-center mb-3"><span className="text-theme fs-12 fw-semi-bold ms-1 cursor-pointer" > {timerCount ? `Resend Code in: ${timerCount}` : <b className="cursor-pointer" onClick={() => resendOtpNow()}>Resend Code</b>} </span></p>

                </div>
              </div>
              <div className="modal-footer border-0 pb-4 pt-2 p-4">
                <button type="submit" className="btn btn-primary bg-dark shadow-none w-100 m-auto rounded-pill mb-2 text-capitalize" disabled={loading}>{loading ? <Spinner /> : 'VERIFY NOW'}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
}
const logOut = ({ logout }) => {
  return <div className="modal fade" id="logOutModal" tabIndex="-1" aria-labelledby="logOutModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="container">
        <form onSubmit={(e) => { e.preventDefault(); logout() }}>
          <div className="col-md-8 m-auto">
            <div className="modal-content">
              <div className="modal-body border-0 text-center">
                <button id="closeVerifyEmail" type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0 border-0" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
                <div>

                  <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-2 d-flex justify-content-center">
                    <span className="text-gradient">Logout</span></h5>
                  <p>Are you sure want to logout?</p>

                  <div className="d-flex align-items-center mt-4">
                    <button type="submit" className="btn btn-primary bg-dark shadow-none w-100 m-auto rounded-pill mb-2 text-capitalize flex-grow-1 me-1" data-bs-dismiss="modal" aria-label="Close" >Yes</button>
                    <button type="button" className="btn btn-outline-primary bg-dark shadow-none w-100 m-auto rounded-pill mb-2 text-capitalize flex-grow-1 ms-1" data-bs-dismiss="modal" aria-label="Close" >no</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
}

export default {
  ReportModal,
  NftCreateStep,
  NftPurchaseStep,
  SelectDate,
  InstagramFiles,
  TiktokFiles,
  VerifyEmail,
  logOut,
  CreateCollection,
  ImportCollection,
  PutOnSaleModal,
  CreateAnAuction,
  PlaceABid,
  PlaceOffer,
  BuyNft,
  Followers,
  Following,
  BurnNft,
  TikTokRequest,
  Report,
  StartDatePicker,
  EndDatePicker,
  Confirm,
  VerificationOfEmail,
  OpenWallet,
  Warning,
  Congratulation,
}

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { FIREBASE_CONFIG, FIREBASE_VA_API_KEY } from "./context/actionTypes";

const app = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
// if (!firebase.getApps.length) {
//     firebase.initializeApp(firebaseConfig)
// }

export const getFirebaseMessageToken = async () => {
    const messaging = getMessaging(app);
    if (isSupported()) {
        try {
            let tokenId = await getToken(messaging, { vapidKey: FIREBASE_VA_API_KEY, })
            return { tokenId }
        } catch (error) {
            console.log("Error", error);
            return { error }
        }
    } else {
        return { error: " Notification Not Supported" }
    }
}

export const onMessageListener = () => {
    const messaging = getMessaging(app);
    return new Promise((resolve, reject) => {
        if (messaging) {
            onMessage(messaging, (payload) => {
                resolve(payload)
            });
        } else {
            reject(null)
        }
    })
}

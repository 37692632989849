import { useState } from "react"
import henceforthValidations from "../../utils/henceforthValidations"
import { Spinner } from "../BootstrapCompo"

export default ({ putOnSaleLoading, reCreateAuction, erc }) => {

    const [price, setPrice] = useState("")
    const [quantity, setQuantity] = useState("")

    const initialisePutOnSale = async (auctionType, quantity, end_date, price, start_date) => {
        let apiRes = await reCreateAuction(auctionType, quantity, end_date, price, start_date)
        if (apiRes) {
            setPrice("")
            setQuantity("")
        }
    }

    return <div className="modal fade" id="putOnSaleFixedPrice" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="fixedPriceModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-0">

                                <h5 className="modal-title w-100 text-center text-dark fw-bold fs-4 pb-2  pt-4 d-flex justify-content-center" id="fixedPriceModalLabel">
                                    <span className="text-gradient">Fixed Price</span></h5>
                                <button id="closePutOnSaleFixedPrice" type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
                            </div>
                            <div className="modal-body pt-1 pb-0 p-4">

                                <p className="text-center mb-2">Enter new price. Your NFT will be pushed in top of marketplace.</p>
                                {/* <p className="text-theme text-center fw-medium">1 ETH = $2,811</p> */}
                                <div className="position-relative my-3">
                                    <input type="text" className="form-control border-pink pe-5" min={0} placeholder="Enter Price " value={price} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) || (e.target.value.includes('.')) ? setPrice(e.target.value) : setPrice("")} />
                                    <p className="fs-12 fw-medium position-absolute top-50 end-0 translate-middle-y me-2">ELEN</p>
                                </div>
                                {erc === 1155 &&
                                    <div className="position-relative my-3">
                                        <input type="text" className="form-control border-pink pe-5" placeholder="1" value={quantity} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setQuantity(e.target.value) : setQuantity(1)} />
                                        <p className="fs-12 fw-medium position-absolute top-50 end-0 translate-middle-y me-2">COPIES</p>
                                    </div>}
                            </div>
                            <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                <button type="button" className="btn btn-primary bg-dark shadow-none w-100 m-auto rounded-pill mb-2 text-capitalize" onClick={() => initialisePutOnSale(1, Number(quantity ? quantity : 1), 0, Number(price), 0)} disabled={putOnSaleLoading || Number(price) <= 0}>{putOnSaleLoading ? <Spinner /> : 'PUT ON SALE'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
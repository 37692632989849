
import Personalnfo from "../assets/images/home/personal_info.svg";
import ChangePassword from "../assets/images/home/change_password.svg";
import Logout from "../assets/images/home/logout.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/Provider";
export const MyAccount = () => {

    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);
    const { authState, accounts, scrollToTop, logOutNow } = useContext(GlobalContext)
    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token])

    const logout = () => {
        if (logOutNow()) {
            navigate(`/signin`)
        }
    }
    return (
        <>
            <section className="edit-profile">
                <div className="container">
                    <div className="my-wallet mx-auto">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="fw-bold text-start fs-3 mb-4"><span className="text-gradient">My Account</span></h4>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-xl-8">
                                <div className="row">
                                    <Link to="/profile/edit" className="col-md-6 mb-lg-4 mb-2">
                                        <div className="border border-pink rounded-3 p-4">
                                            <h6 className="mb-1 fw-semi-bold">  <img src={Personalnfo} className="me-2" />Personal info</h6>
                                            <p className="text-gray mb-0 mt-2">Edit your personal info name, email, profile photo.</p>
                                        </div>
                                    </Link>
                                    <Link to="/password/change" className="col-md-6 mb-lg-4 mb-2">
                                        <div className="border border-pink rounded-3 p-4">
                                            <h6 className="mb-1 fw-semi-bold">  <img src={ChangePassword} className="me-2" />Change Password</h6>
                                            <p className="text-gray mb-0 mt-2">Update your password and secure your account.</p>
                                        </div>
                                    </Link>
                                    <div className="col-md-12">

                                        <button className=" bg-white w-100 mb-0 mt-2 d-flex justify-content-between align-items-center border border-pink rounded-3 px-4 py-3 " onClick={() => logout()} >
                                            <h6 className="mb-0 fw-semi-bold">Log out</h6>
                                            <img src={Logout} className="img-fluid" />
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}



import { Spinner } from "../BootstrapCompo"
import NftCard from "../nftCard"

export default ({ items, _id, elenToUsd }) => (<div className="container p-0">
    <div className="row">
        {Array.isArray(items.data) && items.data.map(res => {
            return <div key={res._id} className="col-xl-4 col-md-6 mb-3">
                <NftCard currentUser={_id} elenToUsd={elenToUsd} {...res} />
            </div>
        })}
    </div>
    {items.total_count === 0 &&
        <div className="empty-content text-center">
            <div>
                <h6 className="fw-semi-bold mb-1">Nothing Found</h6>
                <p>We couldn't find anything with this <br />criteria.</p>
            </div>
        </div>}
    {/* {items.total_count === null && <Spinner />} */}
</div>)
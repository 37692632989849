import { useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import henceforthApi from "../../utils/henceforthApi"
import henceforthValidations from "../../utils/henceforthValidations"
import { getDiscountPercent, subscribeFormatter } from "../../utils/work_contant"
import { Spinner } from "../BootstrapCompo"

export default ({ loading, elenBalance, collection_id, createNewBid, name, erc, bidDetails, setBidDetails, calculateWrappedAmount, highest_bidder, authState }) => {

    const [price, setPrice] = useState("")
    const [quantity, setQuantity] = useState("")

    const onSubmit = async (quantity, price, highest_bidder) => {
        let tempPrice = calculateWrappedAmount(Number(price), quantity ? quantity : 1, getDiscountPercent(elenBalance)).actualPrice
        if (tempPrice > elenBalance) {
            return toast.warn('You have insufficient balance')
        }
        await createNewBid(quantity, price, highest_bidder)
        setPrice("")
        setQuantity("")
    }

    return <div className={`modal fade ${bidDetails ? 'show' : ''}`} tabIndex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" role={bidDetails ? 'dialog' : ''} style={{ display: bidDetails ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} >
        <div className="modal-dialog modal-dialog-centered">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-0 ">
                                <h5 className="modal-title w-100 text-center text-theme fw-bold fs-4 pb-2  pt-4 d-flex justify-content-center " id="exampleModalLabel">Place a bid</h5>
                                <button type="button" className="btn btn-icon shadow-none fs-6 position-absolute top-0 end-0 me-3 mt-2" onClick={() => setBidDetails(null)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                            <div className="modal-body pt-1 pb-0 p-4">
                                <p className="mb-2 text-center">You are about to bid for <strong>{name}</strong> from <strong>@{collection_id?.name}</strong> collection.</p>
                                <div className="input-group mb-3 border  mt-3">
                                    <input type="text" className="form-control shadow-none border-0 rounded-2" placeholder="Enter Bid" aria-label="Recipient's username"
                                        aria-describedby="basic-addon2" value={price} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) || (e.target.value.includes('.')) ? setPrice(e.target.value) : setPrice("")} />
                                    <p className="fs-12 fw-medium position-absolute top-50 end-0 translate-middle-y me-2">ELEN</p>
                                </div>
                                {erc === 1155 &&
                                    <div className="position-relative my-3">
                                        <input type="text" className="form-control border-pink pe-5" placeholder="1" value={quantity} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setQuantity(e.target.value) : setQuantity(1)} />
                                        <p className="fs-12 fw-medium position-absolute top-50 end-0 translate-middle-y me-2">COPIES</p>
                                    </div>}
                                <div className="highhest-bid d-flex justify-content-between align-items-center  border-bottom pb-2">
                                    <div className="bid-left">
                                        <p className="fs-6 text-black-50 mb-0"><small>{highest_bidder?.bid_amount ? 'Highest bid' : 'NFT price'}</small></p>
                                    </div>
                                    <div className="bid-right text-end">
                                        <p className="fs-6 fw-medium text-black mb-0 text-end"><small>{subscribeFormatter(highest_bidder?.bid_amount ? highest_bidder?.bid_amount : bidDetails?.price)} ELEN</small></p>
                                    </div>
                                </div>
                                <div className="highhest-bid d-flex justify-content-between align-items-center  border-bottom pb-2">
                                    <div className="bid-left">
                                        <p className="fs-6 text-black-50 mb-0"><small>Your bidding balance</small></p>
                                        <p className="fs-6 text-black-50 mb-0"><small>Service fee</small></p>
                                    </div>
                                    <div className="bid-right text-end">
                                        <p className="fs-6 fw-medium text-black mb-0 text-end"><small>{subscribeFormatter(price * Number(quantity ? quantity : 1))} ELEN</small></p>
                                        <p className="fs-6 fw-medium text-black mb-0"><small>{getDiscountPercent(elenBalance)}%</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 pb-0 pt-2 p-4 justify-content-start">
                                <div className={`bid-left w-100 d-flex justify-content-between align-items-center ${elenBalance === null ? 'pb-4' : ''}`}>
                                    <p className="fs-6 text-black-50 mb-0"><small>You will pay</small></p>
                                    <h6 className="mb-0 fs-6  fw-medium"><small>{subscribeFormatter(calculateWrappedAmount(Number(price), quantity ? quantity : 1, getDiscountPercent(elenBalance)).actualPrice)} ELEN</small></h6>
                                </div>
                                {elenBalance !== null &&
                                    <div className="bid-left w-100 d-flex justify-content-between align-items-center pb-3">
                                        <p className="fs-6 text-black-50 mb-0"><small>Your wallet balance</small></p>
                                        <h6 className="mb-0 fs-6  fw-medium"><small>{subscribeFormatter(Number(elenBalance).toFixed(2))} ELEN</small></h6>
                                    </div>}

                                {authState?._id ?
                                    <button type="button" className="btn btn-outline-primary shadow-none w-100 mx-3 text-capitalize button-custom-outline-hover" disabled={Number(price ? price : 0) <= 0 || Number(quantity ? quantity : 1) > Number(bidDetails?.quantity) || loading} onClick={() => onSubmit(quantity ? quantity : 1, price, highest_bidder)}>{loading ? <Spinner /> : highest_bidder?._id === authState?._id ? 'BID AGAIN' : 'PLACE A BID'}</button> :
                                    <Link to={`/signin`} type="button" className="btn btn-primary shadow-none w-100  mx-3 mb-2 text-capitalize">Login First</Link>}

                            </div>
                            <div className="p-4 pt-0">
                                <div className="text-center mb-3">
                                    <button style={{ fontSize: '14px' }}  onClick={() => setBidDetails(null)} className="btn shadow-none bg-transparent p-0 text-decoration-underline border-0" type="button"><small>Cancel</small></button>
                                </div>
                                    <p className="m-0" style={{ fontSize: '10px' }}>* Your fee will be calculated based upon the number of tokens you hold when the owner of the NFT accepts your bid. If you agree with this then click on "{highest_bidder?._id === authState?._id ? 'bid again' : 'place a bid'}" to confirm the bid, otherwise click on "cancel".</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
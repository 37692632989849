import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/images/home/logo.svg";
import authAction from "../../context/actions/auth/authAction";
import { WALLET_ADDRESS_SLICE_FROM } from "../../context/actionTypes";
import { GlobalContext } from "../../context/Provider";
import henceforthApi from "../../utils/henceforthApi";
import henceforthValidations from "../../utils/henceforthValidations";
import metamaskContracts from "../../utils/metamaskContracts";
import { getPrimaryWalletType } from "../../utils/work_contant";
import { Spinner } from "../BootstrapCompo";
export default () => {
  const navigate = useNavigate()
  const location = useLocation()
  var query = new URLSearchParams(location.search);
  const { authDispatch, getProvider } = useContext(GlobalContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [passwordToggle, setPasswordToggle] = useState(false)

  const handleLogin = async () => {
    if (!email) {
      return toast.warn("Please enter your email id")
    } else if (!password) {
      return toast.warn("Please enter password")
    } else if (!henceforthValidations.strongPassword(password)) {
      return toast.warn("Please enter stronger password. Try a mix of letters, numbers and symbols.")
    }

    let items = {
      user_name: email,
      password,
      language: "ENGLISH"
    }
    try {
      setLoading(true)
      let apiRes = await henceforthApi.Auth.login(items)
      let data = apiRes.data
      let walletDetails = getPrimaryWalletType(data.wallet)
      let walletType = walletDetails?.type ? walletDetails?.type : null
      if (walletType === null) {
        authAction.login({ ...data })(authDispatch);
        navigate(`/signup/step-2`)
      } else {
        const { accounts, eth_chainId } = await getProvider(walletType)
        if (eth_chainId !== 97 || eth_chainId !== 56) {
          await metamaskContracts.RequestMetamaskChangeChain("binance")
        }
        if (accounts?.length) {
          let walletAddress = String(walletDetails.address).toLowerCase()
          if (String(walletAddress).toLowerCase() !== String(accounts[0]).toLowerCase()) {
            toast.warn(`Please connect ${walletAddress?.slice(0, WALLET_ADDRESS_SLICE_FROM)}....${walletAddress?.slice(walletAddress.length - WALLET_ADDRESS_SLICE_FROM, walletAddress?.length)}`)
            setLoading(false)
            return
          }
        } else {
          setLoading(false)
          toast.warn("Connect wallet first.")
          return
        }
        setLoading(false)
        authAction.login({ ...data, walletType: walletDetails, provider: walletType })(authDispatch);
        if (walletDetails) {
          if (query.has("redirect")) {
            navigate(`${query.get("redirect")}`, { replace: true })
          } else {
            navigate(`/profile/on_sale`, { replace: true })
          }
        } else {
          navigate(`/signup/step-3`, { replace: true })
        }
      }
    } catch (error) {
      console.log("login error", error);
      setLoading(false)
      if (error.response) {
        toast.error(error.response.body.error_description)
      }
    }

  }

  useEffect(() => {
    const closeSelectFile = document.getElementsByClassName("modal-backdrop")
    if (closeSelectFile.length) {
      closeSelectFile[0].classList.remove("show")
    }
  }, [])
  return (
    <div className="auth-page position-relative min-vh-100 d-flex align-items-start">
      <div className="container-fluid h-100">
        <div className="row h-100 align-items-center">
          <div className="col-md-5">
            <div className="s-logo position-absolute">
              <Link to="/">
                <img src={Logo} className="img-fluid header-logo" />
              </Link>

            </div>
            <form className="signup-form" onSubmit={(e) => { e.preventDefault(); handleLogin() }}>
              <h3 className="fw-bold mb-4 mt-4">Sign In</h3>
              <div className="form-group">
                <label className="fw-medium mb-1">Username</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">@</span>
                  </div>
                  <input type="text" className="form-control" placeholder="Username" aria-label="Username" name="username" onChange={(e) => setEmail(e.target.value)} aria-describedby="basic-addon1" required />
                </div>

              </div>
              <div className="form-group">
                <label className="fw-medium mb-1">Password</label>
                <div className="position-relative">
                  <input type={passwordToggle ? 'text' : 'password'} className="form-control pe-5" placeholder="Password" name="password" onChange={(e) => setPassword(e.target.value)} required />
                  <i className={`fa-solid ${passwordToggle ? 'fa-eye-slash' : 'fa-eye'} position-absolute top-50 translate-middle-y end-0 me-3 text-gray`} onClick={() => setPasswordToggle(!passwordToggle)}></i>
                </div>
                <p className=" text-end mt-2"><Link to="/password/forgot" className="text-decoration-none text-theme fw-medium" >Forgot Password?</Link></p>
              </div>
              <button type="submit" className="btn btn-primary w-100 text-uppercase py-2 mt-3" disabled={loading}>{loading ? <Spinner /> : 'SIGN IN'}</button>
              <div className="text-center">
                <p className="text-gray mt-4">Don't have an account?  <Link to="/signup" className="text-theme">Sign Up</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import henceforthApi from "../../utils/henceforthApi";
import henceforthValidations from "../../utils/henceforthValidations";
import { Spinner } from "../BootstrapCompo";

var countdownTimer = null;

export default ({ email }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [timerCount, setTimerCount] = useState(30)


    const first_number_ref = useRef(null)
    const second_number_ref = useRef(null)
    const third_number_ref = useRef(null)
    const forth_number_ref = useRef(null)
    const [first_number, setFirstNumber] = useState("")
    const [second_number, setSecondNumber] = useState("")
    const [third_number, setThirdNumber] = useState("")
    const [forth_number, setForthNumber] = useState("")

    var query = new URLSearchParams(location.search);

    const initialiseInterval = () => {
        var _second = 30
        function timer() {
            if (_second < 1) {
                clearInterval(countdownTimer);
                countdownTimer = null
                return;
            } else {
                _second -= 1
            }
            setTimerCount(_second)
        }
        countdownTimer = setInterval(() => timer(), 1000);
    }

    const sendOtp = async () => {
        debugger
        setLoading(true)
        try {
            let apiRes = await henceforthApi.Auth.resendOtp({
                email: email ? email : 'test@yopmail.com',
                language: "ENGLISH"
            })
            toast.success(apiRes.message)
            query.set("action", "otp")
            navigate({ search: query.toString() })
            if (countdownTimer == null) {
                initialiseInterval()
            }
        } catch (error) {
            toast.error(error?.response?.body?.error_description)
        } finally {
            setLoading(false)
        }
    }
    const verifyOtp = async () => {
        setLoading(true)
        try {
            let otpInStr = String(`${first_number}${second_number}${third_number}${forth_number}`).trim()
            if (otpInStr.length !== 4) {
                return toast.warn("Please enter 4 digit OTP")
            }
            let items = {
                otp: Number(otpInStr),
                language: "ENGLISH"
            }
            let apiRes = await henceforthApi.Auth.emailVerification(items)
            toast.success(apiRes.message)
            query.set("action", "otp")
            navigate(`/signup/step-2`)
        } catch (error) {
            // toast.error(error?.response?.body?.error_description)
            setFirstNumber("")
            setSecondNumber("")
            setThirdNumber("")
            setForthNumber("")
            query.set("action", "resend")
            navigate({ search: query.toString() })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (first_number) {
            second_number_ref.current?.focus()
        }
    }, [first_number])
    useEffect(() => {
        if (second_number) {
            third_number_ref.current?.focus()
        } else {
            first_number_ref.current?.focus()
        }
    }, [second_number])
    useEffect(() => {
        if (third_number) {
            forth_number_ref.current?.focus()
        } else {
            if (first_number) {
                second_number_ref.current?.focus()
            }
        }
    }, [third_number])
    useEffect(() => {
        if (!forth_number && third_number) {
            third_number_ref.current?.focus()
        }
    }, [forth_number])

    useEffect(() => {
        if (countdownTimer == null && query.has('verify') && (query.get('verify') === 'email')) {
            initialiseInterval()
        }
    }, [query])

    return <div className={`modal fade common-modal  ${(query.has('verify') && (query.get('verify') === 'email')) ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={(query.has('verify') && (query.get('verify') === 'email')) ? 'dialog' : ''} style={{ display: (query.has('verify') && (query.get('verify') === 'email')) ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <Link to={`/signup/step-2`}>
                    <button type="button" className="btn-close shadow-none"></button>
                </Link>
                {query.get('action') === 'email' &&
                    <div className="modal-body text-center">
                        <h5 className="fw-semi-bold">We send you the verification link on your email. Please verify your email.</h5>
                        <button className="btn btn-primary py-2 mt-4" onClick={() => {
                            query.set("action", "otp")
                            navigate({ search: query.toString() })
                        }} disabled={loading}>{loading ? <Spinner /> : 'VERIFY EMAIL'}</button>
                    </div>}
                {query.get('action') === 'otp' &&
                    <div className="modal-body text-center">
                        <h5 className="fw-semi-bold">Enter the 4 digit verification code sent to<br /> your email address.</h5>
                        <div className="d-flex justify-content-center mt-4">
                            <input type="text" className="form-control otp-inputs" maxLength={1} id="first_number" value={first_number} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setFirstNumber(e.target.value) : console.log('')} ref={first_number_ref} placeholder="0" autoFocus />
                            <input type="text" className="form-control otp-inputs" maxLength={1} id="second_number" value={second_number} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setSecondNumber(e.target.value) : console.log('')} ref={second_number_ref} placeholder="0" />
                            <input type="text" className="form-control otp-inputs" maxLength={1} id="third_number" value={third_number} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setThirdNumber(e.target.value) : console.log('')} ref={third_number_ref} placeholder="0" />
                            <input type="text" className="form-control otp-inputs" maxLength={1} id="forth_number" value={forth_number} onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setForthNumber(e.target.value) : console.log('')} ref={forth_number_ref} placeholder="0" />
                        </div>
                        <p className="mt-2 mb-0">{timerCount ? `Resend code in` : ''} <span className="text-theme">{timerCount ? timerCount > 9 ? timerCount : `0${timerCount}` : <b className="cursor-pointer" onClick={!loading ? sendOtp : console.log(loading)}>Resend Code</b>}</span></p>
                        <button className="btn btn-primary py-2 mt-5" onClick={verifyOtp} disabled={loading}>{loading ? <Spinner /> : 'VERIFY OTP'}</button>
                    </div>}

                {query.get('action') === 'resend' &&
                    <div className="modal-body text-center">
                        <h5 className="fw-semi-bold">Sorry, we are unable to verify your email</h5>
                        <button className="btn btn-primary py-2 mt-4" onClick={sendOtp} disabled={loading}>{loading ? <Spinner /> : 'RESEND'}</button>
                    </div>}
            </div>
        </div>
    </div>
}
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/images/home/logo.svg";
import henceforthApi from "../../utils/henceforthApi";
import { Spinner } from "../BootstrapCompo";
export default () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const forgotPassword = async () => {
    try {
      let items = {
        email,
        language: "ENGLISH"
      }
      setLoading(true)
      let apiRes = await henceforthApi.Auth.forgotPassword(items)
      toast.success(apiRes.message)
      setLoading(false)

      // let openInfoModal = document.getElementById("openInfoModal")
      // if (openInfoModal) {
      //   openInfoModal.click()
      // }
      navigate(`/signin`)

    } catch (error) {
      setLoading(false)
      if (error.response && error.response.body) {
        toast.error(error.response.body.error_description)
      }
    }

  }
  return <div className="auth-page position-relative min-vh-100 d-flex align-items-start">
    <div className="container-fluid h-100">
      <div className="row h-100 align-items-center">
        <div className="col-md-5">
          <div className="s-logo position-absolute">
            <Link to="/">
              <img src={Logo} className="img-fluid header-logo" />
            </Link>

          </div>
          <form className="signup-form" onSubmit={(e) => { e.preventDefault(); forgotPassword() }}>
            <h3 className="fw-bold mt-4">Forgot Password</h3>
            <p className="text-gray mb-4 fw-medium">Please enter your registered email.</p>
            <div className="form-group">
              <label className="fw-medium mb-1">Email</label>
              <input type="email" className="form-control" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <button type="submit" className="btn btn-primary w-100 text-uppercase py-2 mt-3" disabled={loading}>{loading ? <Spinner /> : 'NEXT'}</button>
            <div className="text-center">
              <p className="text-gray mt-4"><Link to="/signin" className="text-theme">Back to Login</Link></p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button id="openInfoModal" className="d-none" data-bs-toggle="modal" data-bs-target="#openInfo">Change Password</button>

    <div className="modal fade common-modal" id="openInfo" tabIndex="-1" aria-labelledby="openInfoLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body text-center">
            <h5 className="fw-semi-bold">We send you the forget password link<br /> on your email.</h5>
            {/* <button className="btn btn-primary py-2 mt-4">NEXT</button> */}
          </div>

        </div>
      </div>
    </div>
  </div>
}

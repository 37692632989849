import { useState } from "react";
import { toast } from "react-toastify";
import henceforthApi from "../../utils/henceforthApi";
import { ellipsis } from "../../utils/work_contant";
import { Spinner } from "../BootstrapCompo";
import profile_placeholder from "./../../assets/images/home/img_placeholder.svg";

export default ({ data, inialiseFollowing, authState }) => {
    const [loading, setLoading] = useState(false)

    const onUnfollow = async (user_id, index) => {
        debugger

        try {
            let items = {
                user_id,
                language: "ENGLISH"
            }
            setLoading(index)
            await henceforthApi.Follower.unfollow(items)
            await inialiseFollowing(0)
            setLoading(-1)
        } catch (error) {
            setLoading(-1)
            toast.error(JSON.stringify(error?.response?.body?.error_description))
        }
    }
    const onFollow = async (user_id, index) => {
        debugger
        try {
            let items = {
                user_id,
                language: "ENGLISH"
            }
            setLoading(index)
            await henceforthApi.Follower.follow(items)
            await inialiseFollowing(0)
            setLoading(-1)
        } catch (error) {
            debugger
            setLoading(-1)
            toast.error(JSON.stringify(error?.response?.body?.error_description))
        }
    }
    return <div className="modal fade" id="followingModal" tabIndex="-1" aria-labelledby="followingModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body pt-1 pb-0 p-4 ">
                    <h5 className="modal-title w-100 text-center text-dark fw-bold fs-18 pt-4 d-flex justify-content-center">
                        <span className="text-gradient">Following</span></h5>
                    <button type="button" className="btn border-0  shadow-none fs-6 position-absolute top-0 end-0 border-0" data-bs-dismiss="modal" aria-label="Close">
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <div className="p-4 pt-2">
                        <div className="followers-list-wrap">
                            {Array.isArray(data) && data.map((res, index) =>
                                <div className="d-flex justify-content-between align-items-center py-3 follower-list">
                                    <div className="d-flex align-items-center">
                                        <img src={res.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.profile_pic}` : profile_placeholder} className="f-profile me-3" />
                                        <h6 className="fw-semi-bold mb-0">@{res.user_name ? res.user_name : ellipsis(res.address, 10)}</h6>
                                    </div>
                                    {res?.is_following ?
                                        <button className="btn btn-primary" onClick={() => onUnfollow(res.user_id, index)} disabled={index === loading || !authState?._id || (res.user_id === authState?._id)} >{index === loading ? <Spinner /> : 'UNFOLLOW'}</button> :
                                        <button className="btn btn-primary" onClick={() => onFollow(res.user_id, index)} disabled={index === loading || !authState?._id || (res.user_id === authState?._id)} >{index === loading ? <Spinner /> : 'FOLLOW'}</button>}
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
import profile_placeholder from "./../assets/images/home/img_placeholder_small.svg";
import Instagram from "./../assets/images/instagram_img.png";
import TiktokIcon from "./../assets/images/home/tiktok_big.svg";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { EVERLENS_V2_ERC721_ADDRESS, GlobalContext } from "../context/Provider";
import { toast } from "react-toastify";
import moment from "moment";
import { ERROR_UNAUTHORIZED, EXPLORE_NFT_TYPE_INSTAGRAM, EXPLORE_NFT_TYPE_TIKTOK } from "../context/actionTypes";
import MarketDetailsPlayer from "../components/MarketDetailsPlayer";
import Modals from "../components/Modals";
import { GlobalLoading, Spinner } from "../components/BootstrapCompo";
import { ellipsis, getDiscountPercent, subscribeFormatter, timeSince } from "../utils/work_contant";

import ShareOptions from "../components/ShareOptions";
import { BigNumber, ethers } from "ethers";
import { ERC1155_BSC_MAINNET_ADDRESS_KEY, ERC721_BSC_MAINNET_ADDRESS_KEY } from "../utils/henceforthRpc";
import LineChart from "../components/graphs/recharts/LineChart";
import BidList from "../components/marketplace/BidList";
import OfferList from "../components/marketplace/OfferList";

export default () => {
    var countdownTimer;
    const navigate = useNavigate()
    const location = useLocation()
    var query = new URLSearchParams(location.search);

    const match = useMatch('/token/:token/:id/:tab/:graph_type');
    const { authState, createNftStep, accounts, scrollToTop, elenToUsd, finaliseAuction, finaliseOfferAuction, putOnMarketPlace, makeAuction, calculateWrappedAmount, acceptOfferContract, initialiseElenBalance } = useContext(GlobalContext)
    const [elenBalance, setElenBalance] = useState(null)
    const [acceptOfferLoading, setAcceptOfferLoading] = useState(false)
    const [reportText, setReportText] = useState("")
    const [isExpire, setIsExpire] = useState(null)
    const [bidDetails, setBidDetails] = useState(null)
    const [offerDetails, setOfferetails] = useState(null)
    const [fixedDetails, setFixedDetails] = useState(null)
    const [descriptionReadMore, setDescriptionReadMore] = useState(false)
    const [timerCount, setTimerCount] = useState({
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",
        title: ""
    })
    const [nftsOwners, setNftsOwners] = useState({
        owners: [],
        total_count: 0
    })
    const [nftsBids, setNftsBids] = useState({
        bids: [],
        total_count: 0
    })
    const [nftsOffers, setNftsOffers] = useState({
        offers: [],
        total_count: 0
    })
    const [nftsComments, setComments] = useState({
        comments: [],
        total_count: 0
    })
    const [nftsTrading, setTrading] = useState({
        trading_history: [],
        total_count: 0
    })
    const [nftsGraph, setGraph] = useState({
        data: [],
        total_count: 0
    })
    const [minVoucherDetails, setMinVoucherDetails] = useState({
        _id: '',
    })
    const [putOnSaleLoading, setPutOnSaleLoading] = useState(false)
    const [commentLoading, setCommentLoading] = useState(false)
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    const [buyQuantity, setBuyQuantity] = useState(null)
    const [burnData, setBurnData] = useState("")
    const [formData, setFormData] = useState({
        startDate: 0,
        endDate: 0
    })

    const [state, setState] = useState({
        description: "",
        end_date: "",
        name: "",
        royalties: "",
        start_date: "",
        created_at: "",
        tags: [],
        type: "",
        owner_address: "",
        price: "",
        product_id: "",
        created_by: {
            profile_pic: "",
            first_name: "",
            last_name: "",
            user_name: "",
            _id: "",
        },
        voucher_details: {},
        bids_placed_by: [],
        auction_type: null,
        bid_id: null,
        current_owner: "",
        is_sold: false,
        is_favourite: false,
        order_id: "",
        trading_hstory: [],
        no_of_copies: 0
    })
    henceforthApi.setToken(authState.access_token)
    const handleInput = ({ name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const purchaseNFT = async (_id, owner_address, price, auction_type, salt, quantity, signature, end_date, buyQuantity) => {
        console.log('purchaseNFT called');
        if (authState.access_token) {
            try {
                setCheckoutLoading(true)
                let _etherPrice = ethers.utils.parseEther(Number(price).toFixed(18));
                let _token_id = BigNumber.from(state.token_id)
                let _end_date = Math.round(end_date / 1000)
                const voucher = [_token_id, _etherPrice, Number(auction_type), quantity, _end_date, salt]

                let contractRes = await finaliseAuction(owner_address, voucher, signature, price, buyQuantity, state.token_address, accounts[0], auction_type, state.erc);
                if (contractRes) {
                    let { commission, ...props } = contractRes
                    try {

                        let items = {
                            _id: _id,
                            commission: Number(commission),
                            copies: Number(buyQuantity),
                            language: "ENGLISH"
                        }
                        let apiRes = await henceforthApi.NFT.buy(items);
                        toast.success(apiRes.message);
                        setCheckoutLoading(false)
                        setBuyQuantity(items.copies)
                        await initialiseFromDb()
                    } catch (error) {
                        setCheckoutLoading(false)
                        toast.error(error.error_description);
                    }
                    console.log("contractRes", contractRes);
                } else {
                    setCheckoutLoading(false)
                }
            } catch (error) {
                setCheckoutLoading(false)
                console.log("error", error.message);
            }
        } else {
            closeModals()
            toast.warn("You must login first.")
            navigate(`/signin?redirect=${location.pathname}&action=buy_single`)
        }
    }
    const initialisePurchase = async (voucher_details, buyQuantity) => {

        debugger
        if (!buyQuantity) {
            return toast.warn("Please enter quantity")
        }
        if (buyQuantity > Number(voucher_details.left_quantity)) {
            toast.warn("Please check quantity")
            return null
        }
        await purchaseNFT(voucher_details._id, String(voucher_details.owner_address).toLowerCase(), voucher_details.price, voucher_details.auction_type, voucher_details.salt, voucher_details.quantity, voucher_details.signature, voucher_details.end_date, buyQuantity)
    }

    const closeModals = () => {
        let closeBuyStepModal = document.getElementById("closeBuyStepModal")
        let closeSingleNftBuy = document.getElementById("closeSingleNftBuy")
        let closePutOnSaleFixedPrice = document.getElementById("closePutOnSaleFixedPrice")
        let closePutOnSaleAuctionPrice = document.getElementById("closePutOnSaleAuctionPrice")
        if (closeBuyStepModal) {
            closeBuyStepModal.click()
        }
        if (closeSingleNftBuy) {
            closeSingleNftBuy.click()
        }
        if (closePutOnSaleFixedPrice) {
            closePutOnSaleFixedPrice.click()
        }
        if (closePutOnSaleAuctionPrice) {
            closePutOnSaleAuctionPrice.click()
        }
    }
    const initialiseInterval = (end_date, title) => {
        if (end_date) {
            var _second = 1000;
            var _minute = _second * 60;
            var _hour = _minute * 60;
            var _day = _hour * 24;
            var end = new Date(Number(end_date))
            function timer() {
                var now = new Date();
                var distance = end - now;
                if (distance < 0) {
                    clearInterval(countdownTimer);
                    console.log("initialiseInterval EXPIRED!");
                    setIsExpire(true)
                    return;
                } else {
                    setIsExpire(false)
                }

                var days = Math.floor(distance / _day);
                var hours = Math.floor((distance % _day) / _hour);
                var minutes = Math.floor((distance % _hour) / _minute);
                var seconds = Math.floor((distance % _minute) / _second);
                setTimerCount({
                    ...timerCount,
                    days,
                    hours,
                    minutes,
                    seconds,
                    title
                })
                // console.log("days", days, "hours", hours, "minutes", minutes, "seconds", seconds);
            }
            countdownTimer = setInterval(() => timer(), 1000);
        }
    }

    const initialiseFev = async () => {
        setState({
            ...state,
            is_favourite: !state.is_favourite,
            total_favourites: state.is_favourite ? state.total_favourites - 1 : state.total_favourites + 1
        })
        try {
            let items = {
                "nft_id": match.params.id,
                "language": "ENGLISH"
            }
            let apiRes;
            if (state.is_favourite) {
                apiRes = await henceforthApi.NFT.removeFromFev(items)
                // toast.success(apiRes.message)
            }
            else {
                apiRes = await henceforthApi.NFT.addToFev(items)
                // toast.success(apiRes.message)
            }
            let data = (Array.isArray(apiRes.data) && apiRes.data.length) ? apiRes.data[0] : apiRes.data
            setState({
                ...state,
                ...data
            })
        } catch (error) {
            if (error.response.body.error == ERROR_UNAUTHORIZED) {
                navigate(`/signin?redirect=${location.pathname}`, { replace: true })
            }
        }
    }

    const initialiseFromDb = async () => {
        let params = match.params
        let id = params.id
        if (id) {
            try {
                let apiRes = await henceforthApi.NFT.getById(match.params.id)
                let data = (Array.isArray(apiRes.data) && apiRes.data.length) ? apiRes.data[0] : apiRes.data
                if (moment(Number(data.start_date)).utc().valueOf() > moment().utc().valueOf()) {
                    initialiseInterval(data.start_date, "Auction starts in")
                } else {
                    initialiseInterval(data.end_date, "Auction ends in")
                }
                setState({
                    ...state,
                    ...data,
                })
                await initialiseOwners()
                await initialiseOffers(0)
                await initialiseBids(0)
                await initialiseTrading(0)


            } catch (error) {
                console.log("initialiseFromDb error", error);
            }
        } else {
            toast.warn("ID not found, Please visit explore page.")
        }
    }
    const initialiseOwners = async () => {
        try {
            let apiRes = await henceforthApi.NFT.getOwnersById(match.params.id)
            let data = apiRes.data
            let min_owner = {};
            if (Array.isArray(data.owners)) {
                let sallerOwner = data.owners.filter(res => Object.keys(res.voucher_details).length !== 0)
                if (sallerOwner.length) {
                    min_owner = sallerOwner.reduce((prev, curr) => prev?.voucher_details?.price < curr?.voucher_details?.price ? prev : curr)
                    setMinVoucherDetails(min_owner)
                }
            }
            setNftsOwners(data)
            closeModals()
        } catch (error) {
            console.log("Error", error);
            debugger
        }
    }

    const removeFromSale = async () => {
        try {
            await henceforthApi.NFT.removeFromSale(state.voucher_details?._id)
            await initialiseFromDb()
        } catch (error) {

        }
    }

    const putOnSale = async (auctionType, quantity, end_date, price, start_date) => {

        const owners = nftsOwners.owners
        if (Array.isArray(owners) && owners.length) {
            let findOwner = owners.filter(res => res.owner_id?._id === authState._id)
            if (findOwner.length) {
                let ownerDetails = findOwner[0]
                let remainQty = Number(ownerDetails.quantity)
                if (Number(quantity) > remainQty && state?.v2_nft === false) {
                    toast.warn(`You have only ${remainQty} copies of this NFT`)
                    return null
                }
            }
        }

        try {
            setPutOnSaleLoading(true)
            const voucherItems = await putOnMarketPlace(auctionType, quantity ? Number(quantity) : 1, end_date, price, start_date, state.token_address)
            await henceforthApi.Voucher.create({ ...voucherItems, nft_id: match.params.id, start_date })
            let nftData = await initialiseFromDb()
            setPutOnSaleLoading(false)
            return nftData
        } catch (error) {
            setPutOnSaleLoading(false)
            return null
        }
    }

    const createNewBid = async (quantity, price, highest_bidder) => {
        debugger
        try {
            if (Number(price) <= (highest_bidder.bid_amount ? Number(highest_bidder.bid_amount) : Number(state.price))) {
                toast.warn("Your bid is lower than the previous bid, please bid higher.")
                return null
            }
            setCheckoutLoading(true)
            let auctionRes = await makeAuction(price, quantity)
            if (auctionRes) {
                let items = {
                    _id: match.params.id,
                    voucher_id: bidDetails?._id,
                    bid_amount: Number(price),
                    commission: auctionRes.commission,
                    quantity: Number(quantity),
                    language: "ENGLISH"
                }
                let apiRes = await henceforthApi.NFT.placeBid(items)
                toast.success(apiRes.message)
                await initialiseFromDb()
                await initialiseBids()

                setCheckoutLoading(false)
                setBidDetails(null)
                navigate(`/token/${match.params.token}/${match.params.id}/bids/${match.params.graph_type}`, { replace: true })
                return apiRes
            } else {
                setBidDetails(null)
                setCheckoutLoading(false)
                return auctionRes
            }
        } catch (error) {
            setCheckoutLoading(false)
            setBidDetails(null)
            if (error.response && error.response.body) {
                toast.error(error.response.body.error_description)
            } else {
                console.log("error", error);
            }
            return null
        }

    }
    const placeOrderNow = async ({ _id, address, bid_amount, quantity, voucher_data }) => {
        debugger
        try {
            setCheckoutLoading(true)
            let _etherPrice = ethers.utils.parseEther(Number(voucher_data.price).toFixed(18));
            let _token_id = BigNumber.from(state.token_id)
            let _end_date = Math.round(voucher_data.end_date / 1000)
            const voucher = [_token_id, _etherPrice, voucher_data.auction_type, voucher_data.quantity, _end_date, voucher_data.salt]

            let contractRes = await finaliseAuction(voucher_data.owner_address, voucher, voucher_data.signature, bid_amount, quantity, state.token_address, address, voucher_data.auction_type, state.erc);
            if (contractRes) {
                let items = {
                    _id: match.params.id,
                    language: "ENGLISH"
                }
                let apiRes = await henceforthApi.NFT.placeOrder(items)
                toast.success(apiRes.message)
                await initialiseFromDb()
                await initialiseBids()
                setCheckoutLoading(false)
            }
        } catch (error) {
            if (error.response && error.response.body) {
                toast.error(error.response.body.error_description)
            } else {
                console.log("error", error);
            }
        } finally {
            setCheckoutLoading(false)
        }
    }
    const acceptOffer = async (_id, _price, quantity, placed_by_address) => {
        let price = calculateWrappedAmount(Number(_price), quantity ? quantity : 1).actualPrice

        if (!placed_by_address) {
            return toast.warn('User address not found')
        }
        try {
            setAcceptOfferLoading(true)
            let token_address = state.token_address ? state.token_address : EVERLENS_V2_ERC721_ADDRESS
            debugger
            let _token_id = BigNumber.from(state.token_id)
            let _order_id = BigNumber.from(state.order_id)
            const voucher_data = await acceptOfferContract(_token_id, 2, quantity, 0, price, token_address, _order_id, placed_by_address)
            let _etherPrice = ethers.utils.parseEther(Number(voucher_data.price).toFixed(18));
            let _end_date = Math.round(voucher_data.end_date / 1000)
            const voucher = [_token_id, _etherPrice, voucher_data.auction_type, quantity, _end_date, voucher_data.salt]

            let contractRes = await finaliseOfferAuction(voucher_data.owner_address, voucher, voucher_data.signature, _price, quantity, token_address, placed_by_address, voucher_data.auction_type, state.erc);
            if (contractRes) {
                let items = {
                    offer_id: _id,
                    status: 'ACCEPTED',
                    quantity: Number(quantity),
                    language: "ENGLISH"
                }
                let apiRes = await henceforthApi.NFT.acceptOffers(items)
                toast.success(apiRes.message)
                await initialiseFromDb()
                await initialiseOffers()
            }
        } catch (error) {
            console.log('error', error);
            toast.error(error?.response?.body?.error_description)

        } finally {
            setAcceptOfferLoading(false)
        }
    }
    const onReject = async (_id) => {
        setAcceptOfferLoading(true)
        try {
            let items = {
                offer_id: _id,
                status: 'REJECTED',
                language: "ENGLISH"
            }
            let apiRes = await henceforthApi.NFT.acceptOffers(items)
            await initialiseOffers()
            toast.success(apiRes.message)
        } catch (error) {
            console.log('error', error);
            toast.error(error?.response?.body?.error_description)
        } finally {
            setAcceptOfferLoading(false)
        }
    }


    const submitReport = async () => {
        if (reportText) {
            let items = {
                nft_id: match.params.id,
                report_reason: reportText,
                language: "ENGLISH"
            }
            try {
                let apiRes = await henceforthApi.NFT.report(items)
                toast.success(apiRes.message)
                let closeReportNftModal = document.getElementById('closeReportNftModal')
                if (closeReportNftModal) {
                    closeReportNftModal.click()
                }
            } catch (error) {
                toast.error(error?.response?.body?.error_description)
                console.log("error", error);
            }
        }

    }


    useEffect(() => {
        initialiseFromDb()
    }, [match.params.id])


    const initialiseFromBlockChain = async () => {
        // let nftURI = await getNftURI(BigNumber.from(match.params.token))
    }
    const insertComment = async () => {
        let comment_value = formData.comment_value
        if (!comment_value) {
            return toast.warn("Enter your comment")
        }
        setCommentLoading(true)
        try {
            let items = {
                nft_id: match.params.id,
                comment: comment_value,
                language: "ENGLISH"
            }
            await henceforthApi.NFT.addComment(items)
            await initialiseComments(0)
            setCommentLoading(false)
            handleInput({ name: 'comment_value', value: '' })
        } catch (error) {
            setCommentLoading(false)
            if (error.response.body.error == ERROR_UNAUTHORIZED) {
                navigate(`/signin?redirect=${location.pathname}`, { replace: true })
            }

        }

    }
    const deleteComment = async ({ _id }, index) => {
        try {
            let apiRes = await henceforthApi.NFT.deleteComment(_id)
            await initialiseComments(0)
        } catch (error) {

        }
    }
    const initialiseBids = async (page) => {
        try {
            let apiRes = await henceforthApi.NFT.getBidsById(match.params.id, page, 10)
            let data = apiRes.data
            setNftsBids(data)
        } catch (error) {

        }
    }
    const initialiseOffers = async (page) => {
        try {
            let apiRes = await henceforthApi.NFT.getOfferById(match.params.id, page, 10)
            let data = apiRes.data
            let total_count = data.total_count
            if (total_count === 0) {
                navigate(`/token/${match.params.token}/${match.params.id}/trading/${match.params.graph_type}`, { replace: true })
            }
            setNftsOffers(data)
        } catch (error) {

        }
    }
    const initialiseComments = async (page) => {
        try {
            let apiRes = await henceforthApi.NFT.getCommentsById(match.params.id, page, 10)
            let data = apiRes.data
            setComments(data)
        } catch (error) {

        }
    }
    const initialiseTrading = async (page) => {
        try {
            let apiRes = await henceforthApi.NFT.getTradingById(match.params.id, page, 10)
            let data = apiRes.data
            setTrading(data)
        } catch (error) {

        }
    }
    const initialiseGraph = async () => {
        try {
            let apiRes = await henceforthApi.NFT.getGraphById(match.params.id, String(match.params.graph_type).toUpperCase())
            let data = apiRes.data
            setGraph(data)
        } catch (error) {

        }
    }
    useEffect(() => {
        if (match.params.tab == "comments") {
            initialiseComments(0)
        } else if (match.params.tab == "trading") {
            initialiseTrading(0)
        } else if (match.params.tab == "price") {
            initialiseGraph(0)
        } else if (match.params.tab == "offers") {
            initialiseOffers(0)
        }
    }, [match.params.tab])

    useEffect(() => {
        initialiseGraph()
    }, [match.params.graph_type])

    useEffect(() => {
        initialiseFromBlockChain()
        scrollToTop()
    }, [])

    useEffect(() => {
        if (query.has("action")) {
            let action = query.get("action")
            if (action == "buy_single") {
                purchaseNFT()
            }
        }
    }, [query.get("action")])

    const makeOfferNow = async (quantity, price) => {
        debugger
        try {
            setCheckoutLoading(true)
            let auctionRes = await makeAuction(price, quantity, accounts[0])
            if (auctionRes) {
                let items = {
                    nft_id: match.params.id,
                    amount: Number(price),
                    commission: auctionRes.commission,
                    quantity: Number(quantity),
                    language: "ENGLISH"
                }
                let apiRes;
                if (state.placed_offer) {
                    items['_id'] = state.placed_offer
                    apiRes = await henceforthApi.NFT.updateOffers(items)
                    toast.success(apiRes.message)
                } else {
                    apiRes = await henceforthApi.NFT.placeOffers(items)
                    toast.success(apiRes.message)
                }
                setOfferetails(null)
                await initialiseFromDb()
                await initialiseOffers()

                setCheckoutLoading(false)
                navigate(`/token/${match.params.token}/${match.params.id}/offers/${match.params.graph_type}`, { replace: true })
                return apiRes
            } else {
                setCheckoutLoading(false)
                return auctionRes
            }
        } catch (error) {
            setCheckoutLoading(false)
            toast.error(error?.response?.body?.error_description)
            return null
        }
    }

    const getMinPriceOwner = () => {
        const owners = nftsOwners.owners
        if (Array.isArray(owners) && owners.length) {
            let findOwner = owners.filter(res => res.owner_id?._id === authState._id)
            if (findOwner.length) {
                let ownerDetails = findOwner[0]
                let myVoucherDetails = ownerDetails.voucher_details
                if (Object.keys(myVoucherDetails).length !== 0) {
                    return < button className="btn btn-primary w-100 mt-4 fs-12" onClick={() => removeFromSale()}>REMOVE FROM SALE</button>
                } else {
                    return <div className="mt-4 row">
                        <div className="col-xl pe-xl-1">
                            <button className="btn btn-primary w-100 my-1" data-bs-toggle="modal" data-bs-target="#putOnSaleFixedPrice">PUT ON SALE</button>
                        </div>
                        {state.erc === 721 &&
                            <div className="col-xl ps-xl-1">
                                <button className="btn btn-outline-primary w-100 my-1" data-bs-toggle="modal" data-bs-target="#createAnAuction">CREATE AN AUCTION</button>
                            </div>}
                    </div>
                }
            } else {
                let sallerOwner = owners.filter(res => Object.keys(res.voucher_details).length !== 0)
                if (sallerOwner.length) {
                    let min_owner = sallerOwner.reduce((prev, curr) => prev?.voucher_details?.price < curr?.voucher_details?.price ? prev : curr)
                    return < button className="btn btn-primary w-100 mt-4 fs-12" onClick={() => setFixedDetails({ ...fixedDetails, ...min_owner?.voucher_details, owner_id: min_owner?.owner_id, })}>BUY FOR {subscribeFormatter(min_owner?.voucher_details?.price)} ELEN</button>
                } else {
                    return ''
                }
            }
        } else {
            return ''
        }
    }

    const init = async () => {
        const { balanceOfERC20Tx } = await initialiseElenBalance("")
        if (balanceOfERC20Tx) {
            let balanceOfERC20 = ethers.utils.formatEther(balanceOfERC20Tx)
            setElenBalance(Number(balanceOfERC20))
        }
    }
    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
            if (authState.provider) {
                init()
            }
        } else {
            // navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token, authState.provider])


    return <section className="bid-page my-lg-5 my-4 pb-lg-5 ">
        <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <MarketDetailsPlayer {...state} />
                    <div className="highlighted">
                        {state.auction_type === 3 && <div className="row border rounded-3 px-3 py-3 me-0 ms-0 mt-4">
                            <div className="col-md-6">
                                {state?.highest_bidder?.user_name ?
                                    <div className="time-auticon position-relative">
                                        <p className="fw-medium mb-2"> Highest bid by <span className="text-theme fw-semi-bold"><Link to={`/profile/${state.highest_bidder._id}/on_sale`}><h6 className="mb-0 fs-6 fw-bolder">@{state.highest_bidder ? state.highest_bidder.user_name : ''}</h6></Link></span> </p>

                                        <div className="owner-name d-flex pt-2 align-items-center">
                                            <div className="name-img">
                                                <img src={state?.highest_bidder?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state?.highest_bidder?.profile_pic}` : profile_placeholder} className="large-user" />
                                            </div>
                                            <div className="name-text ms-2">
                                                <h6 className="mb-0 fw-bold">{subscribeFormatter(state?.highest_bidder?.bid_amount ? state?.highest_bidder?.bid_amount : state?.price)} ELEN</h6>
                                                {elenToUsd !== 0 && <p className="fs-12 text-black-50 mb-0 text-break">${subscribeFormatter(Number(state?.highest_bidder?.bid_amount ? state?.highest_bidder?.bid_amount : state?.price) * elenToUsd)} USD</p>}
                                            </div>
                                        </div>
                                    </div> : <div className="time-auticon position-relative">
                                        <p className="fw-medium mb-2">Minimum bid</p>

                                        <div className="owner-name d-flex pt-2 align-items-center">

                                            <div className="name-text ms-1">
                                                <h6 className="mb-0 fw-bold">{state.price} ELEN</h6>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="col-md-6">
                                <div className="live-auticon-right mt-md-0 mt-3">
                                    <p className="fw-semi-bold text-theme mb-2">{timerCount.title}</p>
                                    <div className="times-ends">
                                        <ul className="d-flex justify-content-between ps-0 align-items-center mb-0">
                                            <li>
                                                <h5 className="fw-bold mb-0">{String(timerCount.days).length == 1 ? `0${timerCount.days}` : timerCount.days}</h5>
                                                <p className="mb-0 text-black-50">Days</p>
                                            </li>
                                            <li>
                                                <h5 className="fw-bold mb-0">{String(timerCount.hours).length == 1 ? `0${timerCount.hours}` : timerCount.hours}</h5>
                                                <p className="mb-0 text-black-50">Hour</p>
                                            </li>
                                            <li>
                                                <h5 className="fw-bold mb-0">{String(timerCount.minutes).length == 1 ? `0${timerCount.minutes}` : timerCount.minutes}</h5>
                                                <p className="mb-0 text-black-50">Min</p>
                                            </li>
                                            <li>
                                                <h5 className="fw-bold mb-0">{String(timerCount.seconds).length == 1 ? `0${timerCount.seconds}` : timerCount.seconds}</h5>
                                                <p className="mb-0 text-black-50">Sec</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {state.voucher_details?._id && state.auction_type === 3 &&
                                    <div className="mt-4 row">
                                        <div className="col-md-6 pe-1 ps-0">
                                            <button className="btn btn-primary w-100 fs-12 mb-2" onClick={() => { placeOrderNow(state.highest_bidder) }} disabled={!nftsBids.total_count || !isExpire || checkoutLoading}>{checkoutLoading ? <Spinner /> : 'PLACE AN ORDER'}</button>
                                        </div>
                                        <div className="col-md-6 ps-1 pe-0">
                                            <button className="btn btn-outline-primary w-100 fs-12 fw-medium mb-2" onClick={() => removeFromSale()} disabled={!state.voucher_details?._id}>REMOVE FROM AUCTION</button>
                                        </div>
                                    </div>}
                            </div>
                            {minVoucherDetails._id && String(minVoucherDetails.owner_address).toLowerCase() !== String((Array.isArray(accounts) && accounts.length) ? accounts[0] : '').toLowerCase() && nftsOwners.total_count == 1 &&
                                <div className="col-md-12 text-center mt-4">
                                    < button className="btn btn-primary w-100 mb-2 fs-12" onClick={() => setBidDetails(nftsOwners.owners[0]?.voucher_details)} disabled={isExpire}>{state?.highest_bidder?._id === authState._id ? 'BID AGAIN' : 'PLACE A BID'}</button>
                                </div>}
                        </div>}
                        {state.price !== 0 && state.auction_type === 0 && state.erc === 721 && nftsTrading.total_count !== 0 &&
                            <div className="border rounded-3 py-3 px-lg-5 px-3 mt-lg-4 mt-3 text-center">
                                <h6 className="mb-0">This NFT was sold for <b className="fw-semi-bold">{subscribeFormatter(state.price)} ELEN <br /> {elenToUsd !== 0 && `$${Number(Number(state.price) * elenToUsd).toFixed(2)}`} USD</b></h6>
                            </div>}
                        {state.auction_type === 0 && state.erc === 721 && state.price === 0 && state?.current_owner?._id !== authState?._id &&
                            <div className="col-md-12 text-center mt-4">
                                < button className="btn btn-primary w-100 mb-2 fs-12" onClick={() => setOfferetails(1)} >{state.placed_offer ? 'EDIT OFFER' : 'MAKE OFFER'}</button>
                            </div>}
                        {(state.auction_type === 1 && state.erc === 721) ? authState.access_token ?
                            minVoucherDetails._id && String(minVoucherDetails.owner_address).toLowerCase() !== String((Array.isArray(accounts) && accounts.length) ? accounts[0] : '').toLowerCase() && nftsOwners.total_count == 1 &&
                            < button className="btn btn-primary w-100 mt-4 fs-12" onClick={() => setFixedDetails({ ...fixedDetails, ...nftsOwners.owners[0]?.voucher_details, owner_id: nftsOwners.owners[0]?.owner_id, })}>BUY FOR {subscribeFormatter(nftsOwners.owners[0]?.voucher_details?.price)} ELEN</button> :
                            <Link to={`/signin?redirect=${location.pathname}&action=buy_single`} replace={true}> <button className="btn btn-primary w-100 mt-4 fs-12">BUY FOR {subscribeFormatter(state.price)} ELEN</button></Link> : ''}

                        {state.voucher_details?._id && state.auction_type === 1 && state.erc === 721 &&
                            < button className="btn btn-primary w-100 mt-4 fs-12" onClick={() => removeFromSale()}>REMOVE FROM SALE</button>}

                        {authState._id && state.iam_owner && !state.voucher_details?._id && state.erc === 721 &&
                            <div className="mt-4 row">
                                <div className="col-xl pe-xl-1">
                                    <button className="btn btn-primary w-100 my-1" data-bs-toggle="modal" data-bs-target="#putOnSaleFixedPrice">PUT ON SALE</button>
                                </div>
                                {state.erc === 721 &&
                                    <div className="col-xl ps-xl-1">
                                        <button className="btn btn-outline-primary w-100 my-1" data-bs-toggle="modal" data-bs-target="#createAnAuction">CREATE AN AUCTION</button>
                                    </div>}
                            </div>}
                        {state.erc === 1155 && getMinPriceOwner()}
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="right-side-live ps-lg-3 mt-lg-0 mt-4">
                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <div>
                                <h2 className="fw-bold d-flex align-items-start"><span className="text-gradient line-clamp-3 text-break">{state.name}</span><span className="ms-3 "><img src={state.nft_type == EXPLORE_NFT_TYPE_INSTAGRAM ? Instagram : state.nft_type == EXPLORE_NFT_TYPE_TIKTOK ? TiktokIcon : ''} className="img-fluid icon-top insta-big" /></span></h2>
                                {state.created_at && <h6 className="fs-6 text-black-50">Minted on {moment(Number(state.created_at)).format("MMM DD, YYYY")}</h6>}
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="left-icon bg-white d-flex justify-content-end  my-lg-4 my-2 align-items-center">
                                    <span className="border-start fs-12 border-end-0 border text-gray p-2 share-icon shadow-none d-flex align-items-center" onClick={() => initialiseFev()}>
                                        <i className={`${state.is_favourite ? 'fa-solid text-danger' : 'fa-regular'} fa-heart m-0 fs-6 me-1`} ></i>{state.total_favourites}</span>
                                    <button className="btn border border-top border-bottom share-icon px-3 shadow-none" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-share-alt text-muted m-0 fs-6"></i>
                                    </button>
                                    <ShareOptions />
                                </div>
                                <div>
                                    <button className="btn border border-top border-bottom share-icon dot-icon px-3 shadow-none br-rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-solid fa-ellipsis text-muted fs-6"></i>
                                    </button>
                                    <ul className="dropdown-menu share-dropdown dropdown-menu-end">
                                        <li data-bs-toggle="modal" data-bs-target="#reportModal"><button className="dropdown-item fw-semi-bold text-center fs-14" type="button">Report</button></li>
                                        {state.current_owner === authState._id && <li onClick={() => setBurnData(match.params.id)}><button className="dropdown-item fw-semi-bold text-center fs-14" type="button">Burn</button></li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p className="">{ellipsis(state.description, descriptionReadMore ? state.description.length : 100)}{state.description.length > 100 && <button className="text-theme btn btn-icon fs-14 fw-medium ms-1" onClick={() => setDescriptionReadMore(!descriptionReadMore)}> Read {descriptionReadMore ? 'Less' : 'More'}</button>}</p>
                        <p className="fw-medium text-black">
                            {state.tags.map(res => {
                                return <span>#{res} </span>
                            })}
                        </p>
                        <div className="three-img d-flex mt-sm-4 mt-3 flex-wrap">
                            <div className="owner-name owners d-flex align-items-center">
                                <div className="name-img">
                                    <img src={state?.current_owner?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state?.current_owner?.profile_pic}` : profile_placeholder} className="large-user" />
                                </div>
                                <div className="name-text ms-2">
                                    <p className="text-black-50 mb-0 fs-12">Owned by</p>
                                    {state?.current_owner?._id ?
                                        <Link to={`/profile/${state?.current_owner?._id}/on_sale`}><h6 className="mb-0 fs-6 fw-semi-bold text-truncate">@{state?.current_owner?.user_name}</h6></Link>
                                        : <Spinner />}
                                </div>
                            </div>
                            <div className="owner-name owners ps-xl-3 ms-xl-3 ps-sm-3 ms-sm-3 d-flex align-items-center border-left">
                                <div className="name-img">
                                    {state.created_by?._id &&
                                        <img src={state.created_by.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${state.created_by.profile_pic}` : profile_placeholder} className="large-user" />
                                    }
                                </div>
                                <div className="name-text ms-2">
                                    <p className="text-black-50 mb-0 fs-12">Created by</p>
                                    {state.created_by?._id ?
                                        <Link to={`/profile/${state.created_by?._id}/on_sale`}><h6 className="mb-0 fs-6 fw-semi-bold text-truncate">@{state.created_by.user_name ? state.created_by.user_name : state.created_by?._id}</h6></Link>
                                        : <Spinner />}
                                </div>
                            </div>
                            <div className="owner-name owners ps-xl-3 ms-xl-3 ps-sm-3 ms-sm-3 d-flex align-items-center border-left  placeholder-glow" >
                                <div className="name-img">
                                    {state.collection_id?._id &&
                                        <img src={henceforthApi.FILES.imageMedium(state.collection_id?.img_url)} width={(String(state?.collection_id?.address).toLowerCase() === String(ERC721_BSC_MAINNET_ADDRESS_KEY).toLowerCase() || String(state?.collection_id?.address).toLowerCase() === String(ERC1155_BSC_MAINNET_ADDRESS_KEY).toLowerCase()) ? '45px' : ''} className={(String(state?.collection_id?.address).toLowerCase() === String(ERC721_BSC_MAINNET_ADDRESS_KEY).toLowerCase() || String(state?.collection_id?.address).toLowerCase() === String(ERC1155_BSC_MAINNET_ADDRESS_KEY).toLowerCase()) ? '' : 'large-user'} />
                                    }
                                </div>
                                <div className="name-text ms-2">
                                    <p className="text-black-50 mb-0 fs-12 text-truncate">{ellipsis(state?.collection_id?.symbol, 10)}</p>
                                    {state.collection_id?._id ?
                                        <Link to={`/collection/${state.collection_id?._id}/items`}><h6 className="mb-0 fs-6 fw-semi-bold text-truncate" title={state?.collection_id?.name}>{ellipsis(state?.collection_id?.name, 10)}</h6></Link>
                                        : <Spinner />}
                                </div>
                            </div>
                            {state.erc == 1155 ?
                                <div className="owner-name owners ps-xl-3 ms-xl-3 ps-sm-3 ms-sm-3 d-flex align-items-center border-left">
                                    {(state.auction_type === 3) &&
                                        <div className="name-text">
                                            <p className="text-black-50 mb-0">Minimum bid </p>
                                            <h6 className="mb-0 fs-6 fw-bolder">{subscribeFormatter(state.price)} ELEN</h6>
                                        </div>}

                                    <div className="name-text available-count">
                                        <h6 className="mb-0 fs-6 fw-bolder">{subscribeFormatter(state.available_quantity)} of {subscribeFormatter(state.quantity)} available</h6>
                                    </div>
                                </div> : ''}
                        </div>
                        <div className="nav-tabss mt-sm-4 mt-3">
                            <div className="on-salle pb-lg-5 mb-lg-5">

                                <ul className="nav nav-pills mb-4 border-bottom mt-lg-4" id="pills-tab" role="tablist">
                                    {state.erc === 1155 &&
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${match.params.tab == 'owners' ? 'active' : ''} text-secondary fs-14 fw-semi-bold bg-transparent px-0 me-4`}
                                                id="pills-owner-tab" data-bs-toggle="pill" data-bs-target="#pills-owner" type="button"
                                                role="tab" aria-controls="pills-owner" aria-selected="true" onClick={() => navigate(`/token/${match.params.token}/${match.params.id}/owners/${match.params.graph_type}`, { replace: true })}>Owner
                                            </button>
                                        </li>}
                                    {state.auction_type === 3 || match.params.tab == "bids" || nftsBids.total_count ?
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${match.params.tab == 'bids' ? 'active' : ''} text-secondary fs-14 fw-semi-bold bg-transparent px-0 me-4`}
                                                id="pills-bid-tab" data-bs-toggle="pill" data-bs-target="#pills-bid" type="button"
                                                role="tab" aria-controls="pills-bid" aria-selected="true" onClick={() => navigate(`/token/${match.params.token}/${match.params.id}/bids/${match.params.graph_type}`, { replace: true })}>Bids
                                            </button>
                                        </li> : ''}
                                    {Array.isArray(nftsOffers.offers) && nftsOffers.offers.length !== 0 &&
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${match.params.tab == 'offers' ? 'active' : ''} text-secondary fs-14 fw-semi-bold bg-transparent px-0 me-4`}
                                                id="pills-offers-tab" data-bs-toggle="pill" data-bs-target="#pills-offers" type="button"
                                                role="tab" aria-controls="pills-offers" aria-selected="true" onClick={() => navigate(`/token/${match.params.token}/${match.params.id}/offers/${match.params.graph_type}`, { replace: true })}>Offers
                                            </button>
                                        </li>}
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${match.params.tab == 'trading' ? 'active' : ''} text-secondary fs-14 fw-semi-bold bg-transparent px-0 me-4`}
                                            id="pills-trading-tab" data-bs-toggle="pill" data-bs-target="#pills-trading"
                                            type="button" role="tab" aria-controls="pills-trading" aria-selected="false" onClick={() => navigate(`/token/${match.params.token}/${match.params.id}/trading/${match.params.graph_type}`, { replace: true })}>Trading History
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${match.params.tab == 'comments' ? 'active' : ''} text-secondary fs-14 fw-semi-bold bg-transparent px-0 me-4`}
                                            id="pills-comments-tab" data-bs-toggle="pill" data-bs-target="#pills-comments" type="button"
                                            role="tab" aria-controls="pills-comments" aria-selected="true" onClick={() => navigate(`/token/${match.params.token}/${match.params.id}/comments/${match.params.graph_type}`, { replace: true })}>Comments
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${match.params.tab == 'price' ? 'active' : ''} text-secondary fs-14 fw-semi-bold bg-transparent px-0 me-4`}
                                            id="pills-price-tab" data-bs-toggle="pill" data-bs-target="#pills-price"
                                            type="button" role="tab" aria-controls="pills-price" aria-selected="false" onClick={() => navigate(`/token/${match.params.token}/${match.params.id}/price/${match.params.graph_type}`, { replace: true })}>Price History
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${match.params.tab == 'info' ? 'active' : ''} text-secondary fs-14 fw-semi-bold bg-transparent px-0 me-4`}
                                            id="pills-info-tab" data-bs-toggle="pill" data-bs-target="#pills-info"
                                            type="button" role="tab" aria-controls="pills-info" aria-selected="false" onClick={() => navigate(`/token/${match.params.token}/${match.params.id}/info/${match.params.graph_type}`, { replace: true })}>Additional Info
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content" id="pills-tabContent">
                                    <div className={`tab-pane fade  ${match.params.tab == 'owners' ? 'show active' : ''}`} id="pills-owner" role="tabpanel"
                                        aria-labelledby="pills-owner-tab">
                                        {Array.isArray(nftsOwners.owners) && nftsOwners.owners.map(res =>
                                            <div className="bid-owner w-70 p-3 d-flex justify-content-between align-items-center ps-0 owner-tab">
                                                <div className="owner-name d-flex align-items-start ">
                                                    <div className="name-img">
                                                        <img src={res?.owner_id?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.owner_id?.profile_pic}` : profile_placeholder} className="large-user" />
                                                    </div>
                                                    <div className="name-text ms-3">
                                                        <Link to={`/profile/${res.owner_id?._id}/on_sale`}><h6 className="mb-0 fw-semi-bold">@{res?.owner_id?.user_name}</h6></Link>
                                                        <p className="text-gray mb-0">{res.voucher_details?.left_quantity ? res.voucher_details?.left_quantity : 0}/{res?.quantity ? res?.quantity : 0} on sale for ~{res?.voucher_details?.price ? res?.voucher_details?.price : 0} ELEN each</p>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary fs-12" disabled={!res?.voucher_details?._id || res.owner_id?._id === authState._id} onClick={() => res?.voucher_details?.auction_type === 1 ? setFixedDetails({ ...fixedDetails, ...res?.voucher_details, owner_id: res?.owner_id }) : setBidDetails(res?.voucher_details)}>{res?.voucher_details?.auction_type === 1 ? 'BUY' : res?.voucher_details?.auction_type === 3 ? 'PLACE A BID' : 'NOT FOR SALE'}</button>
                                            </div>)}
                                    </div>
                                    <div className={`tab-pane fade  ${match.params.tab == 'bids' ? 'show active' : ''}`} id="pills-bid" role="tabpanel"
                                        aria-labelledby="pills-bid-tab">
                                        {Array.isArray(nftsBids.bids) ? nftsBids.bids.length !== 0 ? nftsBids.bids.map(res => (
                                            <BidList key={res._id} {...res} elenToUsd={elenToUsd} authState={authState} />
                                        )) : <p>No trading history for create graph</p> : ''}
                                    </div>
                                    <div className={`tab-pane fade  ${match.params.tab == 'offers' ? 'show active' : ''}`} id="pills-offers" role="tabpanel"
                                        aria-labelledby="pills-offers-tab">
                                        {Array.isArray(nftsOffers.offers) ? nftsOffers.offers.length !== 0 ? nftsOffers.offers.map(res => (
                                            <OfferList key={res._id} {...res} elenToUsd={elenToUsd} authState={authState} onClick={acceptOffer} onReject={onReject} loading={checkoutLoading} owner={state?.current_owner?._id === authState?._id} />
                                        )) : <p>No offer history</p> : ''}
                                    </div>
                                    <div className={`tab-pane fade  ${match.params.tab == 'comments' ? 'show active' : ''}`} id="pills-comments" role="tabpanel"
                                        aria-labelledby="pills-home-tab">
                                        {Array.isArray(nftsComments.comments) && nftsComments.comments.map((res, index) => {
                                            return <div className="bid-owner w-75 p-3 d-flex  border-pink rounded-3 justify-content-between mb-3">
                                                <div className="owner-name d-flex align-items-start ">
                                                    <div className="name-img">
                                                        <img src={res.comment_by?.profile_pic ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${res.comment_by?.profile_pic}` : profile_placeholder} className="user" />
                                                    </div>
                                                    <div className="name-text ms-3">
                                                        <h6 className="fw-bold mb-0">{res.comment_by?.name ? res.comment_by?.name : 'Anonymous'}</h6>
                                                        <p className="text-black mb-0 line-clamp-2">{res.comment}</p>
                                                    </div>
                                                </div>
                                                <div className="name-text ms-2 justify-content-end">
                                                    {res.comment_by?._id === authState?._id &&
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <span className="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-vertical text-gray"></i>
                                                            </span>
                                                            <ul className="dropdown-menu py-0 delete-menu">
                                                                <li onClick={() => deleteComment(res, index)}><a className="dropdown-item fs-14" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>}
                                                    <p className="text-black-50 mb-0 fs-12 text-nowrap">{timeSince(new Date(Number(res.created_at)))}</p>
                                                </div>
                                            </div>
                                        })}
                                        <div className="d-flex align-items-center w-75">
                                            <div className="position-relative flex-grow-1">
                                                <textarea type="text" className="form-control pe-5 border-pink" placeholder="Comment here..." name="comment_value" value={formData.comment_value} onChange={(e) => handleInput(e.target)} />
                                                {/* <img src={Smiley} className="position-absolute top-50 translate-middle-y end-0 me-3" /> */}
                                            </div>

                                            <button className="btn btn-outline-primary text-uppercase ms-2 fs-12 fw-medium" onClick={() => insertComment()} disabled={commentLoading}>{commentLoading ? <Spinner /> : 'POST'}</button>
                                        </div>
                                    </div>

                                    <div className={`tab-pane fade  ${match.params.tab == 'trading' ? 'show active' : ''}`} id="pills-trading" role="tabpanel"
                                        aria-labelledby="pills-profile-tab">

                                        {Array.isArray(nftsTrading.trading_history) && nftsTrading.total_count ?
                                            <div className="table-responsive px-1">
                                                <table className="table fs-14 common-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="fw-normal bg-theme text-white">Date</th>
                                                            <th scope="col" className="fw-normal bg-theme text-white">From</th>
                                                            <th scope="col" className="fw-normal bg-theme text-white">To</th>
                                                            {state.erc === 1155 &&
                                                                <th scope="col" className="fw-normal bg-theme text-white">Copies</th>}
                                                            <th scope="col" className="fw-normal bg-theme text-white">Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(nftsTrading.trading_history) && nftsTrading.trading_history.map(res => {
                                                            return <tr key={res._id}>
                                                                <td>{moment(Number(res.created_at)).format("MMM DD, YYYY")} at {moment(Number(res.created_at)).format("hh:mma")}</td>
                                                                <td ><Link to={`/profile/${res.sold_by._id}/on_sale`}><span>@{res.sold_by.user_name}</span></Link></td>
                                                                <td ><Link to={`/profile/${res.sold_to._id}/on_sale`}><span>@{res.sold_to.user_name}</span></Link></td>
                                                                {state.erc === 1155 && <td ><span>{res?.copies}</span></td>}
                                                                <td >{subscribeFormatter(res.price)} ELEN</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div> : <p>No trading history found</p>}
                                    </div>

                                    <div className={`tab-pane fade  ${match.params.tab == 'price' ? 'show active' : ''}`} id="pills-price" role="tabpanel"
                                        aria-labelledby="pills-contact-tab">
                                        {nftsGraph.total_count !== 0 ?
                                            <Fragment>
                                                <div className="text-end me-3">
                                                    <select name="cars" id="cars" className="fs-14 option-select fw-medium" value={String(match.params.graph_type).toLowerCase()} onChange={(e) => navigate(`/token/${match.params.token}/${match.params.id}/${match.params.tab}/${e.target.value.toLowerCase()}`)}>
                                                        <option value={String("LAST_7_DAYS").toLowerCase()}>LAST 7 DAYS</option>
                                                        <option value={String("LAST_14_DAYS").toLowerCase()}>LAST 14 DAYS</option>
                                                        <option value={String("LAST_30_DAYS").toLowerCase()}>LAST 30 DAYS</option>
                                                        <option value={String("LAST_60_DAYS").toLowerCase()}>LAST 60 DAYS</option>
                                                        <option value={String("LAST_90_DAYS").toLowerCase()}>LAST 90 DAYS</option>
                                                        <option value={String("LAST_YEAR").toLowerCase()}>LAST YEAR</option>
                                                        <option value={String("ALL_TIME").toLowerCase()}>ALL TIME</option>
                                                    </select>
                                                </div>
                                                <div className="chartjs">
                                                    <LineChart {...nftsGraph} />
                                                </div>
                                            </Fragment> : <p>No trading history for create graph</p>}
                                    </div>

                                    <div className={`tab-pane fade  ${match.params.tab == 'info' ? 'show active' : ''}`} id="pills-info" role="tabpanel"
                                        aria-labelledby="pills-info-tab">

                                        <div className="d-flex align-items-center ">
                                            <p className="info-name">Contract address</p>
                                            <a href={`https://${henceforthApi.IS_STAGING ? 'testnet.' : ''}bscscan.com/address/${state.token_address ? state.token_address : EVERLENS_V2_ERC721_ADDRESS}`} target="_blank">
                                                <p className="fw-semi-bold text-decoration-underline">{state.token_address ? state.token_address : EVERLENS_V2_ERC721_ADDRESS}</p></a>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="info-name">Token ID</p>
                                            <a href={`https://${henceforthApi.IS_STAGING ? 'testnet.' : ''}bscscan.com/token/${state.token_address ? state.token_address : EVERLENS_V2_ERC721_ADDRESS}?a=${parseInt(BigNumber.from(match.params.token)._hex, 16)}`} target="_blank">
                                                <p className="fw-semi-bold text-decoration-underline">{parseInt(BigNumber.from(match.params.token)._hex, 16)}</p></a>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="info-name">Blockchain</p>
                                            {/* <p className="fw-semi-bold">Binance Smart Chain</p> */}
                                            <p className="fw-semi-bold">Binance</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modals.ReportModal text={reportText} setText={setReportText} onSubmit={submitReport} />
        <Modals.BurnNft {...state} data={burnData} setData={setBurnData} />
        <Modals.PutOnSaleModal {...state} putOnSaleLoading={putOnSaleLoading} reCreateAuction={putOnSale} calculateWrappedAmount={calculateWrappedAmount} />
        <Modals.CreateAnAuction {...state} putOnSaleLoading={putOnSaleLoading} reCreateAuction={putOnSale} calculateWrappedAmount={calculateWrappedAmount} />
        <Modals.PlaceABid {...state} loading={checkoutLoading} bidDetails={bidDetails} setBidDetails={setBidDetails} createNewBid={createNewBid} calculateWrappedAmount={calculateWrappedAmount} highest_bidder={state.highest_bidder} authState={authState} elenBalance={elenBalance} />
        <Modals.PlaceOffer {...state} loading={checkoutLoading} details={offerDetails} setDetails={setOfferetails} makeOfferNow={makeOfferNow} calculateWrappedAmount={calculateWrappedAmount} authState={authState} elenBalance={elenBalance} />
        <Modals.BuyNft {...state} loading={checkoutLoading} details={fixedDetails} setDetails={setFixedDetails} initialisePurchase={initialisePurchase} calculateWrappedAmount={calculateWrappedAmount} elenBalance={elenBalance} />
        <Modals.NftPurchaseStep createNftStep={createNftStep} calculateWrappedAmount={calculateWrappedAmount} />
        <Modals.Congratulation show={buyQuantity && getDiscountPercent(elenBalance) != 3} setBuyQuantity={setBuyQuantity}>
            <div>
                <h5 className="fw-bold mb-3 text-center"><span className="text-gradient">Congratulation</span></h5>
                <div className="modal-body pt-1 pb-0 p-4">
                    <p className="text-center mb-2">You are about to buy <strong>{state.name}</strong></p>

                    <div className="highhest-bid d-flex justify-content-between align-items-center  border-bottom pb-2 mt-3">
                        <div className="bid-left">
                            <p className="fs-6 text-black-33 mb-0"><small>NFT price</small></p>
                            <p className="fs-6 text-black-33 mb-0"><small>Original service fee</small></p>
                            {getDiscountPercent(elenBalance) != 3 &&
                                <p className="fs-6 text-black-33 mb-0"><small>Reduced service fee</small></p>}
                        </div>
                        <div className="bid-right">
                            <p className="fs-6 text-black mb-0 text-end fw-medium"><small>{subscribeFormatter(Number(state?.price))} ELEN</small></p>

                            <p className="fs-6 text-black mb-0 fw-medium"><small> {henceforthApi.SERVICE_FEE}% </small></p>

                            {getDiscountPercent(elenBalance) != 3 &&
                                <p className="fs-6 text-black mb-0 fw-medium"><small>{getDiscountPercent(elenBalance)}%</small></p>}
                        </div>
                    </div>
                </div>

                <div className="modal-footer border-0 pt-2">
                    <div className={`bid-left w-100 d-flex justify-content-between align-items-center`}>
                        <p className="fs-6 text-black-50 mb-0"><small>You have paid</small></p>
                        <h6 className="mb-0 fs-6  fw-medium"><small>{subscribeFormatter(calculateWrappedAmount(Number(state?.price), buyQuantity ? buyQuantity : state?.erc === 721 ? 1 : 0, getDiscountPercent(elenBalance)).actualPrice)} ELEN</small></h6>
                    </div>
                </div>
            </div>
        </Modals.Congratulation>
        {acceptOfferLoading ? <GlobalLoading /> : ''}


    </section >
}
import { useState } from "react"
import { Spinner } from "../BootstrapCompo"

export default ({ data, setData, onSubmit }) => {
    const [loading, setLoading] = useState(false)

    return <div className={`modal fade common-modal report-modal ${data ? 'show' : ''}`} data-bs-backdrop="static" tabIndex="-1" role={data ? 'dialog' : ''} style={{ display: data ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <button type="button" className="btn-close shadow-none" onClick={() => setData(null)}></button>
                <div className="modal-body text-center">
                    <h6 className="text-gray mb-4 fw-normal">Open your wallet and click on <strong>sign</strong> button</h6>
                    <button className="btn btn-primary py-2 mt-1 w-100" disabled={loading} onClick={onSubmit}>{loading ? <Spinner /> : 'OPEN WALLET'}</button>
                </div>
            </div>
        </div>
    </div>
}


import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Profile from "../assets/images/home/img_placeholder_small.svg";

import Receive from "../assets/images/home/receive.png";
import Send from "../assets/images/home/send.png";
import { Spinner } from "../components/BootstrapCompo";
import SignUpStep3 from "../components/layout/signup/SignUpStep3";
import { SOCIAL_TYPE_META_MASK, WALLET_ADDRESS_SLICE_FROM } from "../context/actionTypes";
import { GlobalContext } from "../context/Provider";
import { subscribeFormatter } from "../utils/work_contant";

export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, scrollToTop, accounts, initialiseElenBalance, elenToUsd, } = useContext(GlobalContext)
    const [ethBalance, setEthBalance] = useState(0)
    const [elenBalance, setElenBalance] = useState(null)
    const init = async () => {
        const { balanceOfERC20Tx, accountBalance } = await initialiseElenBalance("")
        if (accountBalance) {
            let balanceInEth = ethers.utils.formatEther(accountBalance)
            setEthBalance(balanceInEth)
        }
        if (balanceOfERC20Tx) {
            let balanceOfERC20 = ethers.utils.formatEther(balanceOfERC20Tx)
            setElenBalance(balanceOfERC20)
        }
    }
    useEffect(() => {
        if (authState.access_token) {
            scrollToTop()
            if (authState.provider) {
                init()
            }
        } else {
            navigate(`/signin?redirect=${location.pathname}`, { replace: true })
        }
    }, [accounts, authState.access_token, authState.provider])

    return authState.wallet && authState.wallet.length ? < section className="edit-profile" >
        <div className="container">
            <div className="my-wallet mx-auto">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="fw-bold text-start fs-3 mb-4"><span className="text-gradient">My Wallet</span></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div className="etherum-wallet border rounded-3 text-center mb-3">
                            <p className="mb-2 mt-2"><small className="fw-semi-bold text-gray">Wallet</small><span className="text-black fw-bold ms-1">{elenBalance == null ? <Spinner /> : elenBalance ? Number(elenBalance)?.toFixed(2) : '0.00'} ELEN</span></p>
                            {elenToUsd !== 0 && <h4 className="mb-1 fw-bold">{elenBalance ? `$${subscribeFormatter(Number(Number(elenBalance) * elenToUsd)?.toFixed(3))}` : ''}</h4>}
                            {accounts.length != 0 &&
                                <p className="bg-light p-1 w-25  m-auto mb-3 rounded-pill text-muted" onClick={() => { navigator.clipboard.writeText(accounts[0]); toast.success("Copied to clipboard") }}><small>{accounts.length ? `${accounts[0].slice(0, WALLET_ADDRESS_SLICE_FROM)}....${accounts[0].slice(accounts[0].length - WALLET_ADDRESS_SLICE_FROM, accounts[0].length)}` : ''}</small></p>}
                        </div>
                    </div>
                    {/* <div className="col-md-5">
                        <div className="etherum-wallet border rounded-3 text-center mb-3"> */}
                    {/* <p className="mb-2 mt-2"><small className="fw-semi-bold text-gray">Everlens Wallet</small><span className="text-black fw-bold ms-1">{balanceInElen == null ? <Spinner /> : balanceInElen ? balanceInElen : '0.00'} ELEN</span></p>
                                <h4 className="mb-1 fw-bold">{(elenToUsd !== 0 && balanceInElen) ? `$${elenToUsd * balanceInElen}` : <Spinner />}</h4>
                                <p className="bg-light p-1 w-25  m-auto mb-3 rounded-pill text-muted"><small>{accounts.length ? `${accounts[0].slice(0, WALLET_ADDRESS_SLICE_FROM)}....${accounts[0].slice(accounts[0].length - WALLET_ADDRESS_SLICE_FROM, accounts[0].length)}` : ''}</small></p> */}
                    {/* <div className="btn btn-primary m-3" >OPEN WALLET</div>
                        </div>
                    </div> */}

                </div>

                {/* <div className="row mb-5">
                    <div className="col-lg-5">
                        <div className="etherum-wallet border rounded-3 text-center h-100 border-pink" >
                            <p className="mb-2 mt-2"><small className="fw-semi-bold text-gray">Ethereum Wallet</small><span className="text-black fw-bold ms-1">0.003 ELEN</span></p>
                            <h4 className="mb-1 fw-bold">$0.00</h4>
                            <p className="w-25  m-auto mb-3 "><span className="bg-theme-light py-1 px-2 rounded-pill text-muted "><small>0x0525...7719</small></span></p>
                            <div className="">
                                <button className="btn btn-primary mb-3 me-2">ADD FUND</button>
                                <button className="btn btn-outline-primary mb-3">SEND</button>
                            </div>
                            <div className="wallet-account px-md-4 px-3 mb-3 mt-2">
                                <div className="wallet-list d-flex justify-content-between mb-2 latest">
                                    <div className="text-start d-flex align-items-center">
                                        <img src={Receive} className="me-2" />
                                        <div className="">
                                            <p className="fw-bold mb-0" >Receive</p>
                                            <p className="text-gray fs-12 mb-0">From: 0xa7a...24a8</p>
                                        </div>

                                    </div>
                                    <div className="text-end">
                                        <p className="fw-bold mb-0" >0.1 ELEN</p>
                                        <p className="text-gray fs-12 mb-0">0.1 ELEN</p>
                                    </div>
                                </div>
                                <div className="wallet-list d-flex justify-content-between mb-2">
                                    <div className="text-start d-flex align-items-center">
                                        <img src={Send} className="me-2" />
                                        <div className="">
                                            <p className="fw-bold mb-0" >Send</p>
                                            <p className="text-gray fs-12 mb-0">From: 0xa7a...24a8</p>
                                        </div>

                                    </div>
                                    <div className="text-end">
                                        <p className="fw-bold mb-0" >0.1 ELEN</p>
                                        <p className="text-gray fs-12 mb-0">0.1 ELEN</p>
                                    </div>
                                </div>
                                <div className="wallet-list d-flex justify-content-between mb-2 ">
                                    <div className="text-start d-flex align-items-center">
                                        <img src={Receive} className="me-2" />
                                        <div className="">
                                            <p className="fw-bold mb-0" >Receive</p>
                                            <p className="text-gray fs-12 mb-0">From: 0xa7a...24a8</p>
                                        </div>

                                    </div>
                                    <div className="text-end">
                                        <p className="fw-bold mb-0" >0.1 ELEN</p>
                                        <p className="text-gray fs-12 mb-0">0.1 ELEN</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-7 ">
                        <div className="etherum-wallet border rounded-3 p-4 border-pink mt-lg-0 mt-3">
                            <h5 className="fw-semi-bold mb-4">Receive</h5>
                            <p className="fw-semi-bold mb-0">Status</p>
                            <p className="text-gray fs-12">Confirmed</p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div>
                                        <p className="fw-semi-bold mb-1">From</p>
                                        <div className="d-flex align-items-center">
                                            <img src={Profile} className="img-fluid user me-2"></img>
                                            <p className="mb-0 fw-medium fs-12 token-id">0x05254F92096f4.....9FA2F07719</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="ps-lg-4 pt-5 pt-lg-0 position-relative forward-arrow">
                                        <p className="fw-semi-bold mb-1">To</p>
                                        <div className="d-flex align-items-center">
                                            <img src={Profile} className="img-fluid user me-2"></img>
                                            <p className="mb-0 fw-medium fs-12 token-id">0x05254F92096f4.....9FA2F07719</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <p className="fw-semi-bold mb-1">Transactions</p>
                                    <div className="d-flex justify-content-between">
                                        <p className="text-gray">Nonce</p>
                                        <p className="fw-medium">0.0107625 ELEN</p>
                                    </div>
                                    <hr className="mt-0" />
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 text-gray">Amount</p>
                                        <p className="fw-semi-bold mb-2">0.1 ELEN</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 text-gray">Gas Limit (Units)</p>
                                        <p className="fw-medium mb-2">60000</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 text-gray">Gas Price</p>
                                        <p className="fw-medium mb-2">2.500000178</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 fw-medium">Total</p>
                                        <p className="fw-semi-bold mb-2">0.10015 ELEN</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </section > : < section className="edit-profile" ><div className="container"><SignUpStep3 /></div></section>
}


